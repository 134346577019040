<template>
  <f7-page
    name="listTemplate"
    :page-content="false"
  >
    <div v-if="canCreate">
      <navbar
        slot="fixed"
        nav-title="Control de calidad"
        menu-btn-enable
        add-btn-template-enable
        :add-btn-callback="() => $f7.views.main.router.navigate('/newtemplate/')"
      />
    </div>
    <div v-else>
      <navbar
        slot="fixed"
        nav-title="Control de calidad"
        menu-btn-enable
      />
    </div>
    <div
      style="padding-bottom: 50px"
    >
      <div>
        <f7-searchbar
          placeholder="Buscar"
          disable-button-text="Borrar"
          @input="performSearch"
        />
      </div>

      <div class="content-list-template">
        <div v-if="templates.length > 0">
          <f7-list>
            <f7-list-item
              v-for="(element, index) in templatesToDisplay"
              :key="index"
              link="#"
              :title="element.name"
              :subtitle="'v. ' + element.current_version"
              @click="setTemplate(element.id)"
            />
          </f7-list>
        </div>
        <div v-else>
          <div class="content-nodata">
            <img
              :src="iconTime"
              alt="time"
            >
            <div class="content-nodata__title">
              No hay formularios
            </div>
            <div class="content-nodata__description">
              Pulse el botón <img
                :src="iconAdd"
                alt="add"
              > para crear un formulario
            </div>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import store from '../js/store/index';
import { mapState } from 'vuex';
import TemplateRepository from '../js/repositories/TemplateRepository';
import icTrash from '../static/img/ic_trash.svg';
import icEdit from '../static/img/ic_edit.svg';
import icTime from '../static/img/ic_time.svg';
import icAdd from '../static/img/ic_new_orange.svg';

export default {
  name: 'ListTemplate',
  components: { Navbar },
  data() {
    return {
      templateSearchQuery: '',
      templates: [],
      opened: false,
      selected: null,
      fecha: '',
      calendarParams: {
        closeOnSelect: true,
        openIn: 'customModal',
        header: false,
        footer: true,
        dateFormat: 'yyyy-mm-dd',
        multiple: false,
      },
      hoyMilis: null,
      canEdit: false,
      canCreate: false,
      canDelete: false,
      rol: this.$f7route.params.idRol,
      titulo: '',
    };
  },
  computed: {
    templatesToDisplay() {
      if (this.templateSearchQuery) {
        const searchQuery = this.templateSearchQuery.toLowerCase();

        return this.templates.filter((template) => {
          // Case insensitive search.
          const name = template.name.toLowerCase();
          return name.includes(searchQuery)
        });
      }

      return this.templates;
    },
    showFecha() {
      return this.fecha;
    },
    iconTrash() {
      return icTrash;
    },
    iconEdit() {
      return icEdit;
    },
    iconTime() {
      return icTime;
    },
    iconAdd() {
      return icAdd;
    },

    ...mapState(['currentRol']),
    ...mapState(['token']),
    ...mapState(['confDatatypes']),
  },
  async mounted() {
    await this.getList();
    if (this.rol) {
      store.commit('setCurrentRol', this.rol);
    }

    // this.candEdit = store.getters.getCanEdit;
    // this.canCreate = store.getters.getCanCreate;
    // this.canDelete = store.getters.getCanDelete;
    this.candEdit = this.token.data.edit;
    this.canCreate = this.token.data.create;
    this.canDelete = this.token.data.delete;

    let tmp = this.token.data.roles.find(
      (element) => element.id === this.currentRol
    );
    this.titulo = 'Configuracion ' + tmp.name;
  },
  methods: {
    async getList() {
      this.templates = [];
      this.templates = await TemplateRepository.getAll();
    },
    formatDate(date) {
      //let date = new Date()
      date = new Date(date);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
      );
    },
    setTemplate(id) {
      store.commit('setCurrentTemplate', id);
      this.$f7.views.main.router.navigate('/detailTemplate/');
    },
    performSearch(event) {
      this.templateSearchQuery = event.target.value;
    },
  },
};
</script>
<style lang="scss">
.content-list-template{
  height: calc(100vh - 104px);
  overflow-y: scroll;
  .content-nodata{
    width: 100%;
    flex-direction: column;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__title{
      font-size: 18px;
      font-weight: 500;
      margin-top: 10px;
      color: #a5a9b8;
    }
    &__description{
      font-size: 14px;
      font-weight: 300;
      margin-top: 10px;
      color: #a5a9b8;
      display: flex;
      align-items: center;
      img{
        width: 19px;
        height: 19px;
        margin: 0 3px;
      }
    }
  }
}
</style>
