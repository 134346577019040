<!--
  |==================|
  |----- INDEX ------|
  |==================|
  |1 - TEXT          |
  |2 - NUMBER        |
  |3 - SELECT        |
  |4 - DATE          |
  |5 - FILES         |
  |6 - SIGN          |
  |7 - HEADER INFO   |
  |==================|
-->

<template>
  <div
    v-if="parsedInput !== null"
    class="main-input-visualizer"
  >
    <!-- 1 - Text Input -->
    <hi-form-input-text
      v-if="parsedInput.dataType === 'text'"
      v-show="!parsedInput.options.isHidden && visibility"
      :label="parsedInput.options.title"
      :inline="parsedInput.options.inline"
      :value="editValue != null ? String(editValue) : String(parsedInput.options.defaultValue)"
      :is-calculated-value="parsedInput.options.calculated"
      :is-manually-edited-value="extraData && extraData.manuallyEdited
        ? extraData.manuallyEdited : false"
      :placeholder="parsedInput.options.placeholder"
      :required="parsedInput.options.required"
      :readonly="readOnlyOptional"
      :password="parsedInput.options.password"
      :textarea="parsedInput.options.textarea"
      :show-q-r-reader="parsedInput.options.readQR"
      :show-barcode-reader="parsedInput.options.readBarcode"
      @new-input-value="(newValue) => setValue(parsedInput.ref, newValue)"
      @reset-manual-edit="resetManualEdit"
      @blur="(finalInputValue) => emitResult(finalInputValue)"
      @open-qr-reader="openQRReader"
      @open-barcode-reader="openBarcodeReader"
    />

    <!-- 2 - Number Input -->
    <!-- 2.1 - Standart Number Input -->
    <!-- TODO: Refactor into a single file component HiFormInputNumber -->
    <f7-list-input
      v-if="isStandardInputNumber"
      v-show="!parsedInput.options.isHidden && visibility"
      :ref="parsedInput.ref"
      :class="computedStandardNumberInputClass"
      :label="internalLabel"
      :placeholder="parsedInput.options.placeholder"
      :min="parsedInput.options.min"
      :max="parsedInput.options.max"
      :value="parsedInput.options.calculated ? getNumberInputValue : currentNumberValue"
      :required="parsedInput.options.required"
      :error-message="currentErrorMessage"
      :error-message-force="isInputNumberInvalid"
      type="number"
      clear-button
      @input="setValue(parsedInput.ref, $event.target.value)"
      @change="emitResult($event.target.value)"
    >
      <span
        v-if="parsedInput.options.calculated && extraData !== undefined && extraData.manuallyEdited"
        slot="label"
        class="icon-reset"
      >
        <f7-button
          fill
          small
          style="display: inline; margin-left: 10px"
          @click="resetManualEdit"
        >
          Reset
        </f7-button>
      </span>
    </f7-list-input>

    <!-- 2.2 - Stepper Number Input -->
    <div
      v-else-if="parsedInput.dataType === 'number' && parsedInput.options.stepper"
      v-show="!parsedInput.options.isHidden && visibility"
      :class="[{'disabled-form': readOnlyOptional}, 'master-stepper', 'padding-top-half']"
    >
      <div class="item-title item-label padding-left">
        {{ parsedInput.options.title }}
      </div>
      <hi-stepper
        bg-color="var(--custom-primary)"
        font-color="#ffffff"
        small
        fill
        v-bind="parsedInput.options"
        :default-value="
          editValue != null
            ? parsedInput.options.calculated ? getNumberInputValue : editValue
            : parsedInput.options.defaultValue
        "
        @final-value="stepperValue"
      />
    </div>

    <!-- 2.3 - ReadOnly Number Input -->
    <f7-list-input
      v-else-if="
        parsedInput.dataType === 'number' &&
          isTrue(readOnlyOptional)
      "
      v-show="!parsedInput.options.isHidden && visibility"
      class="padding-top-half"
      :label="internalLabel"
      :default-value="editValue != null ?
        getNumberInputValue : parsedInput.options.defaultValue"
      disabled
    />

    <!-- 3 - Select Input -->
    <!-- 3.1 - Menos de 4 opciones -->
    <!-- TODO: Refactor into a single file component HiFormInputSelect -->
    <div
      v-if="parsedInput.dataType === 'select' && parsedInput.items.length < 4
        && !parsedInput.options.selectWithoutButtons"
      v-show="!parsedInput.options.isHidden && visibility"
      class="content-button-select padding-top-half"
    >
      <div class="item-title item-label">
        {{ internalLabel }}
      </div>
      <f7-row class="content-buttons">
        <f7-col
          v-for="(option, selectButtons1) in parsedInput.items"
          :key="selectButtons1"
        >
          <f7-button
            :class="{
              'active-option': btnClassPicker(
                editValue != null
                  ? editValue
                  : parsedInput.options.defaultValues,
                option.id
              ),
              'inactive-option': !btnClassPicker(
                editValue != null
                  ? editValue
                  : parsedInput.options.defaultValues,
                option.id
              ),
            }"
            raised
            :round="btnRound"
            :disabled="readOnlyOptional"
            @click="
              selectValues = getSelectedValues(option.id);

              emitResult(selectValues);
            "
          >
            {{ option[parsedInput.options.showKey] }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>

    <!-- 3.2 - select 4 o más opciones -->
    <div
      v-if="parsedInput.dataType === 'select' &&
        (parsedInput.items.length >= 4 || parsedInput.options.selectWithoutButtons)"
      v-show="!parsedInput.options.isHidden && visibility"
      class="content-button-select padding-top-half"
    >
      <div class="item-title item-label">
        {{ internalLabel }}
      </div>
      <f7-list-item
        :ref="parsedInput.ref"
        :title="parsedInput.options.title"
        smart-select
        :smart-select-params="{
          closeOnSelect: !isSelectMultiple(),
          popupCloseLinkText: '',
          searchbar: typeOfPopSmartSelector ==='popup' ? true : false,
          pageTitle: parsedInput.options.title,
          openIn: typeOfPopSmartSelector,
          virtualList: parsedInput.items.length > 50,
          routableModals: false
        }"
      >
        <select
          :name="parsedInput.options.title"
          :multiple="isSelectMultiple()"
          :maxlength="parsedInput.options.maxSelecteditems"
          @change="emitResultSelect($event, this)"
        >
          <option
            value=""
          >
            Sin seleccion
          </option>

          <option
            v-for="(option, selectPopover1) in parsedInput.items"
            :key="selectPopover1"
            :value="option.id"
            :title="option.value"
            :disabled="readOnlyOptional"
          >
            {{ option[parsedInput.options.showKey] }}
          </option>
        </select>
      </f7-list-item>
    </div>

    <!-- 4 - Date && Datetime Input -->
    <hi-form-input-date
      v-if="
        parsedInput.dataType === 'date' || parsedInput.dataType === 'datetime'
      "
      v-show="!parsedInput.options.isHidden && visibility"
      :label="parsedInput.options.title"
      :reference="parsedInput.ref"
      :type="parsedInput.dataType"
      :value="dateValue"
      :readonly="readOnlyOptional"
      :required="parsedInput.options.required"
      :multiple="parsedInput.options.multiple"
      :date-format="internalDateFormat"
      :ranged="parsedInput.options.ranged"
      @new-input-value="setValueAndEmitResult"
    />

    <!-- 5 - Boolean Input -->
    <!-- 5.1 - Checkbox input -->
    <hi-form-input-checkbox
      v-if="parsedInput.dataType === 'boolean'"
      v-show="!parsedInput.options.isHidden && visibility"
      :label="parsedInput.options.title"
      :required="parsedInput.options.required"
      :readonly="readOnlyOptional"
      :checked="editValue != null ? editValue : parsedInput.options.checked"
      @new-input-state="(newValue) => emitResult(newValue)"
    />

    <!-- 5.2 - Toggle input -->
    <hi-form-input-toggle
      v-if="parsedInput.dataType === 'toggle'"
      v-show="!parsedInput.options.isHidden && visibility"
      :label="parsedInput.options.title"
      :checked="editValue != null ? editValue : parsedInput.options.value"
      :readonly="readOnlyOptional"
      @new-input-state="emitResult"
    />

    <!-- 6 - Files Inputs -->
    <!-- 6.1 - File input -->
    <!-- TODO: Refactor into a single file component HiFormInputFile -->
    <div
      v-if="parsedInput.dataType === 'file'"
      v-show="!parsedInput.options.isHidden && visibility"
    >
      <f7-list-item
        class="vux-uploader_input-box"
        style="margin-top:20px"
      >
        <div class="file-tittle-container">
          <span class="file-input-title">
            {{ internalLabel }} <br>
            <span class="max-size-span">
              (max: {{ internalDefaultMaxFileSize }} MB)
            </span>
          </span>
          <f7-button
            class="file-btn active-option"
            :round="btnRound"
            small
            @click="openFileManagePopup"
          >
            Gestionar
          </f7-button>
        </div>
        <div
          :key="componentKey2"
          class="fake-file"
        >
          <div class="fake_input-box input-file-fake">
            <input
              type="file"
              name="uploadInput"

              :multiple="parsedInput.options.multiple"
              @change="
                uploadFile(
                  $event.target.files,
                  parsedInput.ref,
                  parsedInput.ref,
                  internalDefaultMaxFileSize,
                  parsedInput.options.allowedFormats
                )
              "
            >
          </div>
          <p
            v-if="!uploadedFiles[parsedInput.ref]
              || uploadedFiles[parsedInput.ref].files.length === 0"
            class="selected-text"
          >
            Seleccione máximo {{ parsedInput.options.maxFiles }} archivos <br>
            <span class="valid-ext">
              (valido: {{ internalDefaultFileFormats }})
            </span>
          </p>
          <p
            v-if="
              uploadedFiles[parsedInput.ref] &&
                uploadedFiles[parsedInput.ref].files.length === 1
            "
            class="selected-text"
          >
            {{ uploadedFiles[parsedInput.ref].files.length }} archivo
            seleccionado.
            <br>
            <span class="valid-ext">
              (valido: {{ internalDefaultFileFormats }})
            </span>
          </p>
          <p
            v-if="
              uploadedFiles[parsedInput.ref] &&
                uploadedFiles[parsedInput.ref].files.length > 1
            "
            class="selected-text"
          >
            {{ uploadedFiles[parsedInput.ref].files.length }} archivos
            seleccionados. <br>
            <span class="valid-ext">
              (valido: {{ internalDefaultFileFormats }})
            </span>
          </p>
        </div>
      </f7-list-item>
      <f7-popup
        :opened="popupOpened"
        @popup:closed="popupOpened = false"
      >
        <f7-page>
          <f7-navbar title="Gestionar archivos">
            <f7-nav-right>
              <f7-link popup-close>
                Listo
              </f7-link>
            </f7-nav-right>
          </f7-navbar>
          <div
            :key="componentKey"
            class="padding-horizontal"
          >
            <f7-list
              v-if="parsedInput.ref in uploadedFiles"
              class="file-list"
              media-list
              inset
              no-hairlines-md
              resizable
            >
              <f7-list-item
                v-for="(file, filekey) in uploadedFiles[parsedInput.ref].files"
                :key="filekey"
                class="file-row"
              >
                <p>
                  {{ file.name }}
                </p>
                <f7-button
                  fill
                  @click="
                    unlink(
                      file,
                      parsedInput.ref,
                      parsedInput.upperRef,
                      parsedInput.ref
                    )
                  "
                >
                  Eliminar
                </f7-button>
              </f7-list-item>
            </f7-list>
            <f7-list
              v-if="parsedInput.ref in uploadedFiles"
              media-list
              inset
              no-hairlines-md
              resizable
              class="progress-file-popup"
            >
              <f7-list-item :class="[{'disabled-form': readOnlyOptional}, 'size-progress', 'padding-horizontal']">
                <p class="progress-title">
                  <b>Tamaño maximo:</b> {{ parsedInput.options.maxSize }} MB
                </p>
                <div class="percentage-container">
                  <f7-progressbar
                    :color="percentColor"
                    :progress="
                      setProgress(
                        parsedInput.options.maxSize,
                        uploadedFiles[parsedInput.ref].size
                      )
                    "
                  />
                  <b class="percentage">{{ numberPercent }}%</b>
                </div>
              </f7-list-item>
            </f7-list>
          </div>
        </f7-page>
      </f7-popup>
    </div>

    <!-- 6.2 - Gallery input -->
    <!-- TODO: Refactor into a single file component HiFormInputGallery -->
    <f7-list
      v-if="parsedInput.dataType === 'gallery'"
      v-show="!parsedInput.options.isHidden && visibility"
    >
      <f7-list-item :class="[{'disabled-form': readOnlyOptional}, 'content-gallery', 'padding-top-half']">
        <div class="item-title item-label">
          {{ internalLabel }}
        </div>
        <div class="gallery_input-box input-photos-gallery">
          <input
            id="ufile"
            type="file"
            name="uploadInput"
            accept="image/*"
            capture="user"
            :multiple="parsedInput.options.multiple"
            :disabled="readOnlyOptional"
            class="gallery_input"
            @change="setLocalImages($event.target.files)"
          >
        </div>
        <small>Formatos permitidos: .jpg .png</small>
        <small> Seleccione máximo {{ parsedInput.options.maxFiles }} archivos.</small>
      </f7-list-item>
      <div
        :key="componentKey3"
        class="content-gallery-uploaded padding"
      >
        <div
          v-for="(item, index1) in editValue"
          :key="index1"
          class="content-gallery-uploaded__item padding-bottom"
        >
          <img
            :name="item.name"
            :src="item.url"
            alt="gallery"
            @click="galleryDialog(item)"
          >
        </div>
      </div>
    </f7-list>

    <f7-list
      v-if="parsedInput.dataType === 'image'"
      v-show="!parsedInput.options.isHidden && visibility"
    >
      <f7-list-item :class="[{'disabled-form': readOnlyOptional}, 'content-gallery', 'padding-top-half']">
        <div class="item-title item-label">
          {{ internalLabel }}
        </div>

        <hi-take-photo
          :enable-camera="parsedInput.options.enableCamera"
          :enable-gallery="parsedInput.options.enableGallery"
          :max-files="parsedInput.options.maxFiles"
          :max-size="Number(parsedInput.options.maxSize)"
          :stored-images="editValue || []"
          @images-changed="photoEvent"
          @images-removed="photoRemoveEvent"
        />
      </f7-list-item>
    </f7-list>

    <!-- 7 - Sign Input -->
    <f7-row
      v-if="parsedInput.dataType === 'firma'"
      v-show="!parsedInput.options.isHidden && visibility"
      :class="[{'disabled-form': readOnlyOptional}]"
    >
      <pre>{{ parsedInput.options.value }}</pre>
      <hi-form-input-signature
        :label="parsedInput.options.title"
        :reference="parsedInput.options.reference"
        :signature="editValue"
        :required="parsedInput.options.required"
        :are-buttons-rounded="btnRound"
        @new-signature="emitResult"
        @signature-cleared="emitResult('')"
      />
    </f7-row>
    <!-- TODO: Refactor into a single file component HiPopupQR -->
    <f7-popup
      v-if="parsedInput.options.readQR"
      class="popup-qr"
      :opened="popupQROpened"
      @popup:closed="popupQROpened = false"
    >
      <f7-page>
        <f7-navbar title="Lectura">
          <f7-nav-right>
            <f7-link popup-close>
              Guardar
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <hi-read-q-r
            :open-camera="isOpenQR"
            @qr-result="stepperValue"
          />
        </f7-block>
      </f7-page>
    </f7-popup>

    <!-- TODO: Refactor into a single file component HiPopupBarcode -->
    <f7-popup
      v-if="parsedInput.options.readBarcode"
      class="popup-barcode"
      :opened="popupBarcodeOpened"
      :tablet-fullscreen="true"
      @popup:closed="popupBarcodeOpened = false"
    >
      <f7-page>
        <f7-navbar title="Lectura">
          <f7-nav-right>
            <f7-link popup-close>
              Guardar
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <hi-read-barcode
            :open-camera="isOpenBarcode"
            @barcode-result="stepperValue"
          />
        </f7-block>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import moment from 'moment';
import EventBus from '../../js/EventBus';
import icAddFill from '../../static/img/ic_add_fill.svg';
import HiStepper from '../uiBasics/HiStepper.vue';
import HiReadQR from '../uiBasics/HiReadQR.vue';
import HiReadBarcode from '../uiBasics/HiReadBarcode.vue';
import HiFormInputToggle from '../uiFormInputs/HiFormInputToggle.vue';
import HiFormInputSignature from '../uiFormInputs/HIFormInputSignature.vue';
import HiFormInputCheckbox from '../uiFormInputs/HiFormInputCheckbox.vue';
import HiFormInputDate from '../uiFormInputs/HiFormInputDate.vue';
import HiFormInputText from '../uiFormInputs/HiFormInputText.vue';
import HiTakePhoto from '../uiFormsBasics/HiTakePhoto.vue';

export default {
  name: 'HiInputVisualizer',
  components: {
    HiStepper,
    HiReadQR,
    HiReadBarcode,
    HiFormInputToggle,
    HiFormInputSignature,
    HiFormInputCheckbox,
    HiFormInputDate,
    HiFormInputText,
    HiTakePhoto,
  },
  props: {
    rowInput: { type: Object, default: () => {} },
    primary: { type: String, default: 'var(--his-primary)' },
    secondary: { type: String, default: 'var(--his-secondary)' },
    fontColorPrimary: { type: String, default: 'var(--his-primary)' },
    fontColorSecondary: {
      type: String,
      default: 'var(--his-font-color-secundary)',
    },
    btnRound: { type: Boolean, default: false },
    templateIndex: { type: [Number, String], default: null },
    uploadedFiles: { type: Object, default: () => {} },
    images: { type: Object, default: () => {} },
    editValue: { type: [Number, String, Boolean, Array], default: null },
    subTemplateIndex: { type: Number, default: null },
    extraData: { type: Object, default: () => {} },
    readOnlyForm: { type: Boolean, default: false },
    visibility: { type: Boolean, default: true },
  },
  data() {
    return {
      currentNumberValue: null,
      parsedInput: null,
      number: 0,
      MAX_FILE_TOTAL_SIZE_IN_MB: 20,
      DEFAULT_FILE_FORMATS: '.jpg .csv .png .pdf .csv',
      values: {},
      selectValues:
        this.editValue != null
          ? this.editValue
          : this.parsedInput !== undefined
            ? this.parsedInput.options.defaultValue || []
            : [],
      active_el: '',
      propagateResultChanged: true,
      popupOpened: false,
      numberPercent: 0,
      percentColor: '',
      componentKey: 1,
      componentKey2: 1,
      componentKey3: 1,
      localImages: { files: [] },
      checked: null,
      popupQROpened: false,
      popupBarcodeOpened: false,
      currentErrorMessage: '',
    };
  },
  computed: {
    internalDefaultFileFormats() {
      return this.parsedInput.options.allowedFormats
        ? this.parsedInput.options.allowedFormats : this.DEFAULT_FILE_FORMATS;
    },
    internalDefaultMaxFileSize() {
      return this.parsedInput.options.maxSize
        ? this.parsedInput.options.maxSize : this.MAX_FILE_TOTAL_SIZE_IN_MB;
    },
    inputNumberRequiredErrorMessage() {
      return 'Este campo es un campo requerido.';
    },
    invalidIntegerErrorMessage() {
      return 'El campo no permite decimales.';
    },
    inputNumberInvalidRangeErrorMessage() {
      return `El valor debe estar entre ${this.parsedInput.options.min} y ${this.parsedInput.options.max}.`;
    },
    internalLabel() {
      if (this.isUnitDefined) {
        return this.isInputRequired ? `${this.parsedInput.options.title} (${this.parsedInput.options.unit})*` : `${this.parsedInput.options.title} (${this.parsedInput.options.unit})`;
      }
      return this.isInputRequired ? `${this.parsedInput.options.title}*` : this.parsedInput.options.title;
    },
    isInputRequired() {
      return this.parsedInput.options.required;
    },
    isUnitDefined() {
      return this.parsedInput.options.unit ? this.parsedInput.options.unit !== '' : false;
    },
    typeOfPopSmartSelector() {
      return (this.parsedInput.items.length < 5
            && (!this.parsedInput.options.service_id || this.parsedInput.options.service_id === 0))
        ? 'popover' : 'popup';
    },
    internalDateFormat() {
      return Array.isArray(this.parsedInput.options.dateFormat)
        ? this.parsedInput.options.dateFormat[0]
        : this.parsedInput.options.dateFormat;
    },
    customStyles() {
      return {
        '--custom-primary': this.primary,
        '--custom-secondary': this.secondary,
        '--custom-font-color-primary': this.fontColorPrimary,
        '--custom-font-color-secondary': this.fontColorSecondary,
      };
    },
    readOnlyOptional() {
      return this.readOnlyForm ? true : this.parsedInput.options.readOnly;
    },

    icAddFill: () => icAddFill,

    dateValue() {
      if (
        !(
          this.parsedInput.dataType === 'date'
          || this.parsedInput.dataType === 'datetime'
        )
      ) return null;

      let dateValue = null;
      const rawDates = this.editValue != null
        ? this.editValue
        : this.parsedInput.options.defaultValue;

      if (Array.isArray(rawDates)) {
        dateValue = rawDates.map((date) => {
          if (date instanceof Date) return date;
          return new Date(date);
        });
      }

      return dateValue;
    },
    isOpenQR() {
      return this.popupQROpened;
    },
    isOpenBarcode() {
      return this.popupBarcodeOpened;
    },
    isInputNumberInvalid() {
      if (this.isAnInvalidInteger) {
        this.setInvalidIntegerErrorMessage();
        return true;
      }
      if (this.parsedInput.options.required) {
        if (!this.isMinAndMaxDefined && !this.isInputNumberDefined) {
          this.setInputNumberRequiredErrorMessage();
          return true;
        }
        if (!this.isMinAndMaxDefined) {
          return false;
        }
        if (this.isInputNumberDefined && this.isInputNumberInValidRange) {
          return false;
        }
        if (this.isInputNumberDefined && !this.isInputNumberInValidRange) {
          this.setInputNumberInvalidRangeErrorMessage();
          return true;
        }
        this.setInputNumberRequiredErrorMessage();
        return true;
      }
      if (!this.parsedInput.options.required) {
        if (!this.isMinAndMaxDefined) {
          return false;
        }
        if (this.isInputNumberDefined && this.isInputNumberInValidRange) {
          return false;
        }
        if (this.isInputNumberDefined && !this.isInputNumberInValidRange) {
          this.setInputNumberInvalidRangeErrorMessage();
          return true;
        }
        return false;
      }
      return false;
    },
    isInputNumberDefined() {
      const parseCurrentNumberValued = parseInt(this.currentNumberValue, 10);
      if (Number.isNaN(parseCurrentNumberValued)) {
        return false;
      }
      return true;
    },
    isInputNumberInValidRange() {
      if (this.currentNumberValue >= this.parsedInput.options.min
        && this.currentNumberValue <= this.parsedInput.options.max) {
        return true;
      }
      return false;
    },
    isMinAndMaxDefined() {
      return 'min' in this.parsedInput.options && 'max' in this.parsedInput.options;
    },
    isAnInvalidInteger() {
      return !this.parsedInput.options.float
            && (this.currentNumberValue
            && (this.currentNumberValue.toString().includes(',') || this.currentNumberValue.toString().includes('.')));
    },
    isStandardInputNumber() {
      return this.parsedInput.dataType === 'number'
          && !this.parsedInput.options.stepper
          && !this.readOnlyOptional;
    },
    computedStandardNumberInputClass() {
      return this.parsedInput.options.calculated
            && this.extraData !== undefined
            && this.extraData.manuallyEdited
        ? 'input-number content-reset padding-top-half'
        : 'input-number padding-top-half';
    },
    getNumberInputValue() {
      const isFloat = (x) => {
        // check if the passed value is a number
        if (typeof x === 'number' && !Number.isNaN(x)) {
          // check if it is integer
          if (Number.isInteger(x)) return false;
          return true;
        } return false;
      };

      const round = (value, decimals) => Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);

      if (this.parsedInput.dataType === 'number') {
        const decimalPlaces = this.parsedInput.options.decimalPlaces || 2;
        // read only input (original output value: this.editValue)
        if (this.isTrue(this.readOnlyOptional)) {
          if (isFloat(this.editValue)) {
            return round(this.editValue, decimalPlaces);
          } return this.editValue;
        }
        // NOT read only
        //  |- and manually edited (original output value: this.currentNumberValue)
        if (this.extraData && this.extraData.manuallyEdited) return this.currentNumberValue;
        //  |- and NOT manually edited (original output value: this.currentNumberValue)
        if (isFloat(this.currentNumberValue)) {
          return round(this.currentNumberValue, decimalPlaces);
        } return this.currentNumberValue;
      } return null;
    },
  },

  watch: {
    editValue(newValue, oldValue) {
      // for selects, if we dont inspect the value for incoming changes,
      // smart select wont display properly the selected value
      try {
        if (!newValue || !oldValue) return; // otherwise it throws an error because oldValue is null on load
        if (newValue.toString() === oldValue.toString()) return;
        this.updateValue(newValue);
      } catch (e) {
        console.error('watch.editValue', e);
      }
    },

  },
  created() {
    const { max, min } = this.rowInput.options;
    if (!Number.isInteger(max)) {
      delete this.rowInput.options.max;
    }
    if (!Number.isInteger(min)) {
      delete this.rowInput.options.min;
    }
  },
  mounted() {
    EventBus.$on('clean-selector', this.clearSelector);
    this.parsedInput = this.rowInput;
    if (this.parsedInput.dataType === 'number') {
      this.currentNumberValue = this.editValue != null
        ? this.editValue
        : this.parsedInput.options.defaultValue;
    }
    if (this.parsedInput.dataType === 'gallery' && Array.isArray(this.editValue)) {
      this.localImages.files = [...this.editValue];
    }
    // whenever we are creating a smart select, we set the value via f7 api
    // otherwise, values will not work properly when you deselect another value in select
    if ((this.parsedInput.dataType === 'select') && (this.parsedInput.items.length >= 4 || this.parsedInput.options.selectWithoutButtons)) {
      this.$nextTick(() => {
        // we do not want to propagate when in mounted the value is set to the select
        // because when editing a template, the value passed here is already in the forsm visualizer
        // and setting the value here triggers data builder, that triggers evaluator
        // and if evaluator tries to execute a service, params services are not loaded yet, and triggers error
        this.propagateResultChanged = false;
        if (this.parsedInput.options.maxSelecteditems > 1) {
          let valTemp = '';
          if( this.editValue !== null && this.editValue !=='') {
            try {
              valTemp = this.editValue.map((a) => a.toString());
            } catch (error) {
              valTemp = this.parsedInput.options.defaultValue;
            }


          } else {
            valTemp = this.parsedInput.options.defaultValues
          }
          this.$refs[this.parsedInput.ref].f7SmartSelect.setValue(valTemp);
          // this.$refs[this.parsedInput.ref].f7SmartSelect.setValue(
          //   this.editValue != null && this.editValue !== ''
          //     ? this.editValue.map((a) => a.toString())
          //     : this.parsedInput.options.defaultValues,
          // );
        } else {
          this.$refs[this.parsedInput.ref].f7SmartSelect.setValue(
            this.editValue != null
              ? this.editValue
              : this.parsedInput.options.defaultValues,
          );
        }

        this.propagateResultChanged = true;
      });
    }
  },
  beforeDestroy() {
    EventBus.$off('clean-selector');
  },
  destroyed() {
    EventBus.$off('clean-selector');
  },
  methods: {
    clearSelector({ reference, quantity }) {
      if (this.parsedInput.ref === reference) {
        this.updateValue(0);
      }
    },
    openQRReader() {
      this.popupQROpened = true;
    },
    openBarcodeReader() {
      this.popupBarcodeOpened = true;
    },
    stepperValue({ value }) {
      this.emitResult(value);
      this.popupQROpened = false;
      this.popupBarcodeOpened = false;
    },
    isTrue(value) {
      if (value) {
        return true;
      }
      return false;
    },
    isSelectMultiple() {
      return this.parsedInput.options.maxSelecteditems > 1;
    },
    emitResult(value, fromReset = false) {
      if (this.parsedInput.dataType === 'number') {
        if (this.parsedInput.options.stepper) {
          this.currentNumberValue = value;
        } else {
          this.currentNumberValue = this.parsedInput.options.float
            ? parseFloat(parseFloat(this.currentNumberValue).toFixed(20))
            : parseInt(this.currentNumberValue, 10);
        }
      }
      this.$emit('data-event', {
        reference: this.parsedInput.ref,
        upperRef: this.parsedInput.upperRef,
        data:
          this.parsedInput.dataType === 'number' ? parseFloat(this.currentNumberValue) : value,
        index: this.templateIndex,
        fromReset,
      });
    },
    emitResultSelect() {
      if (!this.propagateResultChanged) return;
      const actualValues = this.$refs[this.parsedInput.ref].f7SmartSelect.getValue();
      this.emitResult(actualValues);
    },
    updateValue(val) {
      if (this.parsedInput.dataType === 'number') {
	      this.currentNumberValue = val;
      }

      if (!this.$refs[this.parsedInput.ref]) {
        // Avoid undefined reference Error.
        return;
      }

      if (this.parsedInput.dataType === 'select'
          && (this.parsedInput.items.length >= 4 || this.parsedInput.options.selectWithoutButtons)) {
        if (this.parsedInput.options.maxSelecteditems > 1) {
          this.$refs[this.parsedInput.ref].f7SmartSelect.setValue(
            this.editValue != null
              ? this.editValue.map((a) => a.toString())
              : this.parsedInput.options.defaultValues,
          );
        } else {
          this.$refs[this.parsedInput.ref].f7SmartSelect.setValue(
            this.editValue != null
              ? this.editValue
              : this.parsedInput.options.defaultValues,
          );
        }
      }
    },
    setValueAndEmitResult(newInputValue) {
      this.setValue(this.parsedInput.options.id, this.formatDate(new Date(newInputValue)));
      this.emitResult(newInputValue);
    },
    resetManualEdit() {
      this.$emit('reset-manual-edit', {
        reference: this.parsedInput.ref,
        upperRef: this.parsedInput.upperRef,
        index: this.templateIndex,
      });
    },
    getSelectedValues(itemId) {
      let selectedCopy = null;
      if(Array.isArray(this.selectValues)) {
        selectedCopy = [...this.selectValues];
      } else {
        selectedCopy = [this.selectValues];
      }
      const index = selectedCopy.indexOf(itemId);
      if (index > -1) {
        selectedCopy.splice(index, 1);
      } else {
        if (
          selectedCopy.length + 1
          > this.parsedInput.options.maxSelecteditems
        ) {
          selectedCopy.shift();
        }
        selectedCopy.push(itemId);
      }
      // if(Array.isArray(selectedCopy) && selectedCopy.length === 1 && this.parsedInput.options.maxSelecteditems === 1) {
      //   return selectedCopy[0];
      // }
      return selectedCopy;
    },
    btnClassPicker2(validIds, itemId) {
      if(typeof validIds !== 'number' && validIds.includes(',')){
        validIds = validIds.split(',')
        return validIds.includes(itemId);
      } else {
        if(!isNaN(itemId) && typeof validIds!== 'number') {
          return validIds.includes(itemId);
        } else {
          if(Array.isArray(validIds)) {
            return validIds.includes(itemId);
          } else {
            return validIds === itemId;
          }
        }
      }
      // return validIds.includes(itemId);
    },
    btnClassPicker(validIds, itemId) {
      if(validIds.includes(',')){
        validIds = validIds.split(',')
        return validIds.includes(itemId);
      } else {
        if(!isNaN(itemId) && !isNaN(validIds)) {
          return validIds.includes(itemId);
        } else {
          if(Array.isArray(validIds)) {
            return validIds.includes(itemId);
          } else {
            return validIds === itemId;
          }
        }
      }
      // return validIds.includes(itemId);
    },
    setInputValid(min, max, isValid) {
      if (this.min < isValid || this.max > isValid) return true;
      return false;
    },
    contains(id, items) {
      return items.includes(id);
    },
    setValue(itemId, value) {
      if (this.parsedInput.dataType === 'number') {
        this.currentNumberValue = value;
      }
      this.values[itemId] = value;
      this.active_el = value;
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    customFormatter(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },

    openFileManagePopup() {
      this.updateExistingFiles();

      this.popupOpened = true;
    },

    /**
     * Checks if there are files in the editValue propery.
     * It updates the file input status to reflect the existing files.
     */
    updateExistingFiles() {
      const fileData = this.uploadedFiles[this.parsedInput.ref] || {};
      const currentFiles = (fileData.files || []);

      const existingFiles = this.editValue;

      for (const file of existingFiles) {
        let existsOnCurrent = false;

        for (const exFile of currentFiles) {
          if (file.name == exFile.name) {
            existsOnCurrent = true;
            break;
          }
        }

        if (!existsOnCurrent) {
          this.$emit('file-event', {
            files: [file],
            reference: this.parsedInput.ref,
            inputId: this.parsedInput.ref,
            maxSize: this.internalDefaultMaxFileSize,
            allowedFormats: this.parsedInput.options.allowedFormats,
            index: this.templateIndex,
            upperRef: this.parsedInput.upperRef,
          });
        }
      }
    },

    async uploadFile(files, reference, inputId, maxSize, allowedFormats) {
      this.componentKey2 += 1;

      // Convert current File data into Base64 string
      const base64FilePromises = [];
      const base64Files = [];
      for (let i = 0; i < files.length; i += 1) {
        // get item
        const file = files.item(i);
        base64FilePromises.push(this.toBase64(file));
        base64Files.push({ name: file.name, size: file.size });
      }

      const values = await Promise.all(base64FilePromises);
      values.forEach((code, i) => { base64Files[i].url = code; });

      this.$emit('file-event', {
        files: base64Files,
        reference,
        inputId,
        maxSize,
        allowedFormats,
        index: this.templateIndex,
        upperRef: this.parsedInput.upperRef,
      });
    },
    setProgress(maxSize, currentSize) {
      let allowedSize = 0;
      if(maxSize) {
        allowedSize = maxSize * 1024 ** 2;
      } else {
        allowedSize = this.MAX_FILE_TOTAL_SIZE_IN_MB * 1024 ** 2;
      }

      const percent = (currentSize * 100) / allowedSize;
      this.numberPercent = Math.round(percent);
      this.percentColor = this.setProgressColor(this.numberPercent);
      return percent;
    },
    setProgressColor(progress) {
      if (progress >= 50 && progress <= 74) {
        return 'yellow';
      }
      if (progress >= 75 && progress <= 89) {
        return 'orange';
      }
      if (progress >= 90) {
        return 'red';
      }
      return 'blue';
    },
    unlink(file, inputId, upperRef, reference) {
      const sizeOperator = file.size;
      const index = this.uploadedFiles[inputId].files.indexOf(file);
      this.componentKey += 1;
      this.$emit('unlink-file-event', {
        sizeOperator,
        index,
        inputId,
        upperRef,
        reference,
        templateIndex: this.templateIndex,
      });
    },
    photoEvent(data) {
      this.$emit('gallery-event', {
        file: data,
        inputId: this.parsedInput.ref,
        allowedFormats: this.parsedInput.allowedFormats,
        upperRef: this.parsedInput.upperRef,
        templateIndex: this.templateIndex,
        reference: this.parsedInput.ref,
      });
    },
    photoRemoveEvent(photoIndex) {
      this.editValue.splice(photoIndex, 1);
      this.$emit('unlink-image-event', {
        file: undefined,
        inputId: this.parsedInput.ref,
        upperRef: this.parsedInput.upperRef,
        templateIndex: this.templateIndex,
        reference: this.parsedInput.ref,
      });
    },
    setLocalImages(files) {
      const localImagesCoppy = { ...this.localImages };
      if (this.editValue.length + files.length > this.parsedInput.options.maxFiles) {
        this.$f7.dialog
          .create({
            title: 'Error',
            text: 'Se ha alcanzado la cantidad maxima de archivos permitida.',
            buttons: [
              {
                text: 'Aceptar',
              },
            ],
          })
          .open();
        return;
      }
      Array.from(files).forEach((element) => {
        const fileUp = element;
        const filename = fileUp.name;
        let ext = filename.split('.');
        ext = ext.reverse();
        [ext] = ext;
        (async () => {
          const img64 = await this.toBase64(fileUp);
          const image = {
            name: filename,
            url: img64,
          };
          if (!this.parsedInput.options.hasOwnProperty('allowedFormats')) {
            this.parsedInput.options.allowedFormats = 'jpg png';
          }
          if (this.parsedInput.options.allowedFormats.includes(ext)) {
            localImagesCoppy.files.push(image);
            this.$emit('gallery-event', {
              file: image,
              inputId: this.parsedInput.ref,
              allowedFormats: this.parsedInput.allowedFormats,
              upperRef: this.parsedInput.upperRef,
              templateIndex: this.templateIndex,
              reference: this.parsedInput.ref,
            });
          } else {
            this.$f7.dialog
              .create({
                title: 'Error',
                text: `El archivo ${image.name} no se ha podido subir correctamente debido a que su extensión no es valida.`,
                buttons: [
                  {
                    text: 'Aceptar',
                  },
                ],
              })
              .open();
          }
        })();
      });
      this.localImages = localImagesCoppy;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    galleryDialog(file) {
      this.$f7.dialog
        .create({
          title: 'Opciones',
          text: 'Seleccione una acción a realizar',
          buttons: [
            {
              text: 'Cancelar',
            },
            {
              text: 'Eliminar',
              color: 'red',
              onClick: () => {
                const index = this.editValue.indexOf(file);
                this.editValue.splice(index, 1);
                this.componentKey3 += 1;
                this.$emit('unlink-image-event', {
                  file,
                  inputId: this.parsedInput.ref,
                  upperRef: this.parsedInput.upperRef,
                  templateIndex: this.templateIndex,
                  reference: this.parsedInput.ref,
                });
              },
            },
          ],
          verticalButtons: true,
        })
        .open();
    },
    setInputNumberInvalidRangeErrorMessage() {
      this.currentErrorMessage = this.inputNumberInvalidRangeErrorMessage;
    },
    setInputNumberRequiredErrorMessage() {
      this.currentErrorMessage = this.inputNumberRequiredErrorMessage;
    },
    setInvalidIntegerErrorMessage() {
      this.currentErrorMessage = this.invalidIntegerErrorMessage;
    },
  },
};
</script>

<style lang="scss">
.main-input-visualizer {
  // Nuevos estilos
  .disabled-form {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .7;
  }
  a.link.popup-close:after {
    content: "\d7";
    font-size: 42px;
    color: #FFF;
  }
  .readOnlyText {
    display: flex;
    align-items: center;
  }
  // --------------
  .unlink-btn {
    color: white;
    width: 120px;
  }
  .file-list {
    margin: 0;
    .file-row {
      .item-content {
        .item-inner {
          min-width: 100%;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
        }
      }
    }
  }
  .progress-file-popup {
    margin: 0 auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 2px double rgb(175, 175, 175);
    border-radius: 5px;
    .progress-title {
      margin: 0;
    }
    .percentage-container {
      display: flex;
      align-items: center;
    }
    .percentage {
      margin-left: 10px;
    }
    .size-progress {
      .item-content {
        .item-inner {
          display: block;
        }
      }
    }
  }
  .active-option {
    background-color: var(--custom-primary);
    color: #ffffff;
    border: 1px solid var(--custom-primary);
    margin: 0;
    font-size: small;
  }
  .inactive-option {
    background-color: #ffffff;
    color: var(--custom-primary);
    border: 1px solid var(--custom-primary);
    margin: 0;
    font-size: small;
  }

  .content-gallery{
    .item-inner{
      display: flex;
      flex-direction: column;
      align-items: normal;
    }
  }
  .content-gallery-uploaded{
    display: flex;
    width: 100%;
    font-size: 0;
    flex-wrap:wrap;
    justify-content: flex-start;
    &__item{
      display: inline-block;
      width: calc(100% * (1/3));
      img{
        height: 80px;
        width: 100%;
        max-width: 115px;
        object-fit: cover;
      }
    }
  }

  .vux-uploader_input-box {
    .item-inner{
      display: flex;
      flex-direction: column;
      align-items: normal;
    }
    .file-tittle-container {
      display: flex;
      justify-content: space-between;
    }
    .fake-file {
      min-width: 100%;
      color: rgb(175, 175, 175);
      letter-spacing: 1px;
      font-size: 15px;
      padding-bottom: 5px;
      font-weight: lighter;
    }
    .vux-uploader_input:hover {
      cursor: pointer;
    }
    input[type="file"] {
      width: 100%;
      opacity: 0;
    }
    .selected-text {
      border-top: 1px solid rgb(225, 225, 225);
      margin: 0;
    }
    .max-size-span {
      font-weight: bold;
      font-size: small;
    }
    .valid-ext {
      font-weight: bold;
      font-size: xx-small;
    }
    .file-input-title {
      margin-top: -10px;
    }
  }


  .fake_input-box:before,
  .fake_input-box:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #d9d9d9;
  }
  .fake_input-box:before {
    width: 2px;
    height: 39.5px;
  }
  .fake_input-box:after {
    width: 39.5px;
    height: 2px;
  }



  // gallery
  .gallery_input-box:before,
  .gallery_input-box:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #d9d9d9;
  }
  .gallery_input-box:before {
    width: 2px;
    height: 39.5px;
  }
  .gallery_input-box:after {
    width: 39.5px;
    height: 2px;
  }
  .gallery_input {
    opacity: 0.1;
  }
  .gallery-title {
    color: rgb(160, 160, 160);
    font-size: small;
  }
  .header-info {
    color: var(--custom-font-color-primary);
  }

  .smart-title {
    margin: 5px 15px 0 15px;
    padding: 0;
  }
  .smart-select {
    .item-content {
      padding: 0;
      .item-inner {
        padding-left: 15px;
        .item-after {
          margin-left: 0;
          font-size: 14px;
        }
      }
      .item-title {
        display: none !important;
      }
    }
  }

  .content-button-select {
    padding-left: calc(
      var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left)
    );
    padding-right: calc(
      var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right)
    );
  }
  .disabled, .item-input-with-value{
    .item-inner{
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>
