<template>
  <f7-page
    name="newTemplate"
    :page-content="false"
  >
    <div class="main-newtemplate">
      <!-- Top Navbar -->
      <navbar
        slot="fixed"
        nav-title="Crear formulario"
        back-btn-enable
      />

      <div class="top-bar-new padding">
        <div class="top-bar-new__left" />
        <div class="top-bar-new__right">
          <f7-button
            v-if="canCreate"
            fill
            @click="saveTemplate"
          >
            Guardar
          </f7-button>
        </div>
      </div>
      <div>
        <hi-forms-builder
          v-model="template"
          :forms-types="confDatatypes"
          :services="servicios"
          :entities="entities"
          :change-group-type-restrictions="true"
          @form-data-builder="getBuilder"
          @form-forms="getForms"
          @form-result-forms="getResultsForms"
        />
      </div>
    </div>
  </f7-page>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import HiFormsBuilder from '../components/dynamicforms/HiFormsBuilder.vue';
import Api from '../js/services/Api';
import { mapState } from 'vuex';
import AllServicesRepository from '../js/repositories/AllServicesRepository';
import TemplateRepository from '../js/repositories/TemplateRepository';
import Evaluator from '../js/utilities/evaluator';

export default {
  name: 'NewTemplate',
  components: { Navbar, HiFormsBuilder },
  props: {
    isFromUrlToken: { type: String, default: '' }
  },
  data() {
    return {
      template: null,
      canEdit: false,
      canCreate: false,
      canDelete: false,
      servicios: [],
      entities: [],
      forms: [],
      resultsForms: [],
      evaluator: {
        triggerFields: [],
        calcFields: {},
        mathParser: null,
        instance: null,
      }
    };
  },
  computed: {
    ...mapState([
      'confDatatypes',
      'token',
      'currentRol'
    ]),
  },
  async created() {
    const serviciosTemp = await AllServicesRepository.getByCategory('INPUT');
    serviciosTemp.forEach(element => {
        const item = {
          id: element.id,
          value: element.name
        }
        this.servicios.push(item);
    });

    const entitiesTemp = await AllServicesRepository.getByCategory('FORM');
    entitiesTemp.forEach(element => {
        const item = {
          id: element.id,
          value: element.name
        }
        this.entities.push(item);
    });

    const evaluatorInstance = new Evaluator();
    this.evaluator.instance = evaluatorInstance;
  },
  mounted() {
    this.canEdit = this.token.data.edit;
    this.canCreate = this.token.data.create;
    this.canDelete = this.token.data.delete;
  },
  methods: {
    getForms({ data}) {
      this.forms = data;
    },
    getResultsForms({ data }) {
      this.resultsForms = data;
    },
    getBuilder({ data }) {
      this.template = data;
    },
    checkSave() {
      let message = '';
      // Comprobación de campos obligatorios no establecidos
      this.forms.forEach((element, index) => {
        const requireds = element.detail[0].children.filter((e) => e.options.required === true && e.dataType === 'text')
        requireds.forEach((e1) => {
          if(this.resultsForms[index]['' + e1.ref + ''] === '') {
            if(this.resultsForms[index].ref  !== undefined) {
              message = message + 'El campo "' + e1.ref + '" no tiene valor en #' + this.resultsForms[index].ref + '<br>'
            } else {
              message = message + 'El campo "' + e1.ref + '" no tiene valor en #form<br>'
            }

          }
        });
      });

      // Comprobacion de campos marcados como calculados que no tienen formula ni condiciones de visibilidad
      const requiredCalculated = this.resultsForms.filter((e) => e.calculated === true  && e.formula === '' && e.visibilidad === '');
      requiredCalculated.forEach((e) => {
        message = message + 'El concepto "' + e.ref + '" está marcado como calculado pero sin fórmula y sin condiciones de visibilidad ' + '<br>';
      });

      const requiredCalculated1 = this.resultsForms.filter((e) => e.calculated === true && (e.formula !== '' || e.visibilidad !== ''));

      requiredCalculated1.forEach(async(e) => {
        // Ejecutamos las formulas con los valores por defecto
        let datas = {};
        let subtemplate = false;
        let upperRef = '';
        let itmobj = {}
        const relatedFieldsTmp = e.relatedFields.split(',')
        for(let i=0; i<relatedFieldsTmp.length; i++ ) {
          if(relatedFieldsTmp[i]) {
            const reftmp = relatedFieldsTmp[i]
            let itm = null;
            if(reftmp.includes('.')) {
              subtemplate = true;
              const gritem = reftmp.split('.');
              upperRef = gritem[0]
              itm = this.resultsForms.find((e) => e.ref === gritem[1] && e.upperRef === gritem[0])
              if(!itm) {
                message = message + 'El concepto "' + reftmp + '" no existe en el formulario ' + '<br>';
              } else {
                if(typeof itm.defaultValue === 'undefined') {
                  datas[gritem[0]][gritem[1]] = false
                } else {
                  if(!datas[gritem[0]]  || datas[gritem[0]].length === 0) {
                    datas[gritem[0]] = [];
                  }
                  itmobj[gritem[1]] = itm.defaultValue
                }
              }
            } else {
              itm = this.resultsForms.find((e) => e.ref === reftmp)
              if(!itm) {
                message = message + 'El concepto "' + reftmp + '" no existe en el formulario ' + '<br>';
              } else {
                if(typeof itm.defaultValue === 'undefined') {
                  datas[''+reftmp+''] = false
                } else {
                  datas[''+reftmp+''] = itm.defaultValue
                }
              }
            }
          }
        }
        datas[upperRef].push( itmobj )
        this.evaluator.calcFields[e.ref] = {
          ...e.calcOptions
        };
        this.evaluator.instance.updateScope(datas);
        let result = '';
        if(subtemplate) {
          this.evaluator.instance.updateData(datas);
          if(e.formula) {
            result = this.evaluator.instance.evaluateInSubTemplate(e.formula,upperRef, 0)
          } else if(e.visibilidad) {
            result = this.evaluator.instance.evaluateInSubTemplate(e.visibilidad, upperRef, 0)
          }
        } else {
          if(e.formula) {
            result = this.evaluator.instance.evaluate(e.formula)
          } else if(e.visibilidad) {
            result = this.evaluator.instance.evaluate(e.visibilidad)
          }
        }
        if(result === null) {
          message = message + 'El concepto "' + e.ref + '" está marcado como calculado pero la fórmula o la condición de visibilidad no es correcta ' + '<br>';
        }

      });
      return message;
    },
    async saveTemplate() {
      const message = '';//this.checkSave();
      if(!message) {
        if (this.template && this.template.name) {
            const data = {
            code_external: '-1',
            name: this.template.name,
            description: this.template.description,
            company: this.token.data.company,
            data: JSON.stringify(this.template),
            rol: this.currentRol,
            types: ['001'],
            type: 0
          };

          if (this.template.entity) {
            data.entity = parseInt(this.template.entity);
          }

          const response = await Api.saveTemplate(data);
          if (response.id && response.id > 0) {
            await TemplateRepository.add(response);
            if (this.isFromUrlToken === 'true') {
              this.$f7.views.main.router.navigate('/auto-login/coming-back');
            } else {
              this.$f7.views.main.router.navigate('/home/');
            }
          } else {
            alert('Error');
          }
        } else {
          this.$f7.dialog.alert('No se ha empezado a crear el formulario');
        }
      } else {
        if(message) {
          this.$f7.dialog.alert(message)
        } else {
          //Se ha abierto el formulario pero no se ha editado
          if (this.isFromUrlToken === 'true') {
            this.$f7.views.main.router.navigate('/auto-login/coming-back');
          } else {
            this.$f7.views.main.router.navigate('/home/');
          }
        }
      }
    },
  },
};
</script>
<style lang="sass">
.main-newtemplate{
  .top-bar-new{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D8DFEF;
  }
}

</style>

