<!-- https://github.com/olefirenko/vue-barcode-reader -->
<template>
  <div>
    <p class="error">
      {{ error }}
    </p>

    <p class="decode-result">
      Último Valor Leído: <b>{{ result }}</b>
    </p>

    <stream-barcode-reader
      v-if="openCamera"
      @decode="onDecode"
      @loaded="onLoaded"
    />
  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader';

export default {
  name: 'ReadBarcode',
  components: { StreamBarcodeReader },
  props: {
    openCamera: { type: Boolean, default: false },
  },
  data() {
    return {
      result: '',
      error: '',
      openc: this.openCamera,
    };
  },
  methods: {
    onDecode(result) {
      this.result = result;
      this.$emit('barcode-result', { value: this.result });
    },
    onLoaded() {
      console.log('load');
    },
  },
};
</script>
