import { relevantUrls } from '../constants';
import store from '../store/index'
import EventBus from '../EventBus';

function checkRequiredInputs(resultado, requiredInputs) {
  let areRequiredInputsDefined = false
  const typesChecking = {
    text: (textValue) => (textValue ? textValue !== '' : false),
    select: (selectValue) => selectValue.length > 0,
    date: (dateValue) => !!(dateValue && dateValue.length),
    datetime: (datetimeValue) => !!(datetimeValue && datetimeValue.length),
    boolean: (booleanValue) => booleanValue && booleanValue === true,
    galery: (galeryValue) => galeryValue && galeryValue.length > 0,
    file: (fileValue) => fileValue.length > 0 && fileValue[0] instanceof File,
    firma: (firmaValue) => (firmaValue ? firmaValue !== '' : false),
    number: (numberValue) => !Number.isNaN(numberValue) && typeof numberValue === 'number',
  };
  if (requiredInputs.length > 0) {
    let allRequiredInputsDefined = [];
    requiredInputs.forEach((requiredInput) => {
      const inputTypeChecker = typesChecking[requiredInput.type];
      if (requiredInput.groupRef) {
        if (resultado[requiredInput.groupRef].length > 0) {
          resultado[requiredInput.groupRef].forEach((listTemplateItem) => {
            allRequiredInputsDefined.push(
              inputTypeChecker(listTemplateItem[requiredInput.reference])
            );
          });
        } else {
          allRequiredInputsDefined.push(false);
          return areRequiredInputsDefined;
        }
        return areRequiredInputsDefined;
      }
      allRequiredInputsDefined.push(
        inputTypeChecker(resultado[requiredInput.reference])
      );
    });
    areRequiredInputsDefined = areAllElementsTrueIn(
      allRequiredInputsDefined
    );
    return areRequiredInputsDefined;
  }
  areRequiredInputsDefined = true;
  return areRequiredInputsDefined;
}

function updateRequiredHandler(f7) {
  if (f7.device.cordova) {
    const storeUrl = f7.device.ios ? relevantUrls.iosStore : relevantUrls.androidStore;
    f7.dialog.create({
      title: 'Calidad',
      text: 'Nueva actualización disponible',
      closeByBackdropClick: 'true',
      buttons: [
        {
          text: 'Cerrar',
        },
        {
          text: 'Actualizar',
          onClick() { window.location.href = storeUrl; },
        },
      ],
    }).open();
  }
}


async function userAccess(f7, user, password) {
  const response = await store.dispatch('login', { user, password });
  if (response) {
    // Firebase Login
    try {
        console.log('Firebase Login Event');
        console.log('FirebasePlugin.setUserId', response.id);
        window.FirebasePlugin.setUserId(response.id);
        console.log('FirebasePlugin.setUserProperty', response.id);
        window.FirebasePlugin.setUserProperty('company', response.company_name);
        window.FirebasePlugin.setUserProperty('companyId', response.company);
        console.log('FirebasePlugin.logEvent', 'Login');
        window.FirebasePlugin.logEvent('Login', { content_type: 'Events', item_id: 'Login' });
    } catch (err) {
      console.log(err);
    }

    // check app version
    const newVersionRequired = response?.sms_version;
    if (newVersionRequired === 'new_version') updateRequiredHandler(f7);

    // check first login
    const first_login = response.first_login;

    if (store.state.wantToRemember) {
      localStorage.setItem('username', user);
      localStorage.setItem('password', password);
      store.commit('setLoginUser', user);
    }

    if (!first_login) {
      f7.views.main.router.navigate('/home/');

      EventBus.$emit('full.reload');
    } else {
      f7.views.main.router.navigate('/firstLogin/');
    }
    const limitPreviewObject = response.params.find(
      (param) => param.name === 'FILTER_PREVIEW_ITEMS'
    );
    const limitPreview = limitPreviewObject ? limitPreviewObject.value : 20;
    store.commit('EntityCacheSearch/SET_LIMIT_PREVIEW', limitPreview);
  }
}

export { checkRequiredInputs, updateRequiredHandler, userAccess };

function areAllElementsTrueIn(arrayOfBooleans) {
      return arrayOfBooleans.includes(false) || arrayOfBooleans.includes(undefined) ? false : true;
    }