<template>
  <f7-app
    :params="f7params"
  >
    <hi-side-menu
      :template="menuItemsWithoutAdm"
      :show-icon="showIcon"
      :header-top-title="showTitle"
    />

    <task-loader />

    <!-- Your main view, should have "view-main" class -->
    <f7-view
      main
      class="safe-areas"
      url="/"
    />

    <event-bus-listener />
  </f7-app>
</template>
<script>
  import { Device }  from 'framework7/framework7-lite.esm.bundle';
  import cordovaApp from '../js/cordova-app';
  import routes from '../js/routes';
  // import EventBus from '../js/EventBus';
  // import HiSideMenu from 'vue-component-library/components/uitemplates/HiSideMenu.vue';
  import HiSideMenu from '../components/uitemplates/HiSideMenu.vue';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import TaskLoader from './TaskLoader.vue';
  import EventBusListener from './event-listeners/EventBusListener.vue';
  import { buildMenu } from '../js/lib/menu'
  import { userAccess } from '../js/lib/shared'

  export default {
    components: {
      HiSideMenu,
      TaskLoader,
      EventBusListener,
    },
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          id: 'es.hispatec.calidad', // App bundle ID
          name: 'Calidad', // App name
          theme: 'auto', // Automatic theme detection
          // App routes
          routes: routes,
          // Input settings
          input: {
            scrollIntoViewOnFocus: Device.cordova && !Device.electron,
            scrollIntoViewCentered: Device.cordova && !Device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
            androidTextColor: 'white',
            androidBackgroundColor: '#000000',
            iosTextColor: 'white',
            iosBackgroundColor: '#000000',
          },
        },
        // Login screen data
        username: '',
        password: '',
        title: 'Titulo',
      }
    },
    computed: {
      showTitle() {
        return 'Título';
      },
      showIcon() {
        return true;
      },
      menuItemsWithoutAdm() {
        let menu = null;
        if (Device.cordova) {
          menu = this.menuItems.items.map(x => {
            x.options.items = x.options.items.filter(item => !item.path.includes('/listTemplate'))
          })
          menu = this.menuItems;
          menu.items = menu.items.filter(x => { return x.options.items.length != 0 })
          return menu;
        }

        return this.menuItems;
      },
      ...mapGetters(['menuItems', 'getToken']),
    },
    mounted() {
      this.$f7ready(async (f7) => {
        // Init cordova APIs (see cordova-app.js)
        await this.isExist()
        if (Device.cordova) {
          cordovaApp.init(f7);
        }
        // Call F7 APIs here
    });
  },
  methods: {
    async signIn() {
      let usu = localStorage.getItem('username');
      let pass = localStorage.getItem('password');
      this.$f7.preloader.show();
      try {
        await userAccess(this.$f7, usu, pass);
      } catch (reason) {
        this.$f7.dialog.alert(reason);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    logoutPressed() {
      const self = this;
      this.$f7.dialog.confirm('Seguro que quieres salir?', () => {
        self.$f7.panel.close('left', false);
      });
    },
    async checkCordovaConnectivity() {
      let usu = localStorage.getItem('username');
      let pass = localStorage.getItem('password');
      return await this.hasNetworkConnexion({user: usu, password: pass});
    },
    async isExist() {
      if(localStorage.getItem('username') != null && localStorage.getItem('password') != null) {
        let isOnline = navigator.onLine;
        if (Device.cordova) isOnline = await this.checkCordovaConnectivity();
        if(isOnline) {
          this.signIn();
        } else {
          const offlineMode = async () => {
            const token = await this.offlineLogin();
            buildMenu(token);
            if (token) {
              let _this = this
              setTimeout(() => { _this.$f7.views.main.router.navigate('/home/') }, 1000);
            } else {
              this.$f7.dialog.alert('No hay datos para poder continuar en modo offline');
            }
          }
          this.$f7.dialog.confirm(
            'No hay conexión ¿Desesa continuar en modo offline?',
             async () => { await offlineMode() }
          );
        }
      }
    },
    ...mapMutations('EntityCacheSearch', ['SET_LIMIT_PREVIEW']),
    ...mapActions([
      'login',
      'offlineLogin',
      'offlineModeOn',
      'offlineModeOff',
      'hasNetworkConnexion'])
  }
}
</script>