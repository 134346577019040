<template>
  <div class="main-hiformsbuilder">
    <!-- prevRef: {{ prevRef }} |
    Index_Current: {{ index_current }} |
    counter_current: {{ counter_current }} |
    type_current: {{ type_current }} | -->
    <!-- formCreated: {{ formCreated }} |
    groupCreated: {{ groupCreated }} | -->
    <!-- group_current: {{ group_current }} | -->
    <!-- item_current: {{ item_current }} | -->
    <!-- group_current_type: {{ group_current_type }} |
    <br>
    type_layout: {{ type_layout }} |
    ref_type_layout: {{ ref_type_layout }} |
    <br> -->
    <!-- results_forms: {{ results_forms }} -->
    <!-- bloques: {{ bloques }} -->

    <div class="content-formsbuilder">
      <div class="content-builder">
        <div>
          <div
            v-if="!formCreated"
            class="top-bar padding"
          >
            <div class="top-bar__left">
              <f7-button
                fill
                @click="preAddForm('form')"
              >
                Nuevo
              </f7-button>
            </div>
          </div>
          <f7-block>
            <f7-row>
              <f7-col :style="variableHeight">
                <div
                  v-for="(element, index) in bloques"
                  :key="index"
                  :class="{ 'margin-top margin-group': element.key !== 'form' }"
                >
                  <div class="content-group">
                    <div
                      v-if="element.key !== 'form'"
                      class="content-group-controls"
                    >
                      <div
                        class="content-group-controls__position margin-right"
                      >
                        {{ "Grupo " + index }}
                      </div>
                    </div>

                    <!---->
                    <div class="content-group-child-controls">
                      <div
                        v-if="element.posicion === index_current && element.tipo !== 'groupLayout'"
                        class="content-group-child-controls__button"
                      >
                        <div
                          @click="goUpGroup(element.key,results_forms[element.posicion].ref);"
                        >
                          <f7-icon f7="arrow_up_circle" />
                        </div>
                        <div
                          @click="goDownGroup(element.key,results_forms[element.posicion].ref);"
                        >
                          <f7-icon f7="arrow_down_circle" />
                        </div>
                      </div>
                    </div>
                    <!---->

                    <!-- grupos -->
                    <div
                      class="content-group-data"
                      :class="{
                        'content-group-data--selected':
                          element.posicion === index_current,
                      }"
                      @click="selectForm(element.posicion)"
                    >
                      <div class="content-group__ref">
                        <div>#{{ element.key }}</div>
                      </div>
                    </div>
                    <div class="group-buttons__container">
                      <!-- Button add element -->
                      <f7-link
                        v-if="element.key !== 'form'"
                        popover-open=".popover-menu"
                        @click="selectForm(element.posicion)"
                      >
                        <img
                          width="36px"
                          :src="iconAddelement"
                          alt="Add"
                        >
                      </f7-link>

                      <f7-link @click="promptDeleteGroup(element.key)">
                        <img
                          width="36px"
                          :src="iconTrash"
                          alt="Trash"
                        >
                      </f7-link>
                    </div>
                  </div>

                  <!-- items del group --->
                  <div
                    v-for="(item, ind) in element.items"
                    :key="ind"
                    :style="margin(item.tipo)"
                    :class="{'ml-80': results_forms[item.posicion].isChildrenLayout}"
                    class="flexSpaceAround margin-top"
                  >
                    <div class="content-group-child fullWidth">
                      <div class="input-level">
                        <div class="input-level__container">
                          <div class="input-level__label">
                            {{ index + "." + (ind + 1) }}
                          </div>
                        </div>
                        <div class="content-group-child-controls">
                          <div
                            v-if="item.posicion === index_current && item.tipo !== 'groupLayout'"
                            class="content-group-child-controls__button"
                          >
                            <div
                              @click="goUp(element.key,results_forms[item.posicion].ref);
                                      selectForm(item.posicion-1)"
                            >
                              <f7-icon f7="arrow_up_circle" />
                            </div>
                            <div
                              @click="goDown(element.key,results_forms[item.posicion].ref);
                                      selectForm(item.posicion+1)"
                            >
                              <f7-icon f7="arrow_down_circle" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="content-group-child-item"
                        :class="{
                          'content-group-child-item--selected':
                            item.posicion === index_current,
                        }"
                        @click="selectForm(item.posicion)"
                      >
                        <div class="content-group-child-item__ref">
                          <div>#{{ results_forms[item.posicion].ref }}</div>
                        </div>
                        <div>{{ item.posicion }} - {{ item.tipo }}</div>
                      </div>
                    </div>
                    <div class="group-buttons__container">
                      <!-- Button add element (Group inside list template) -->
                      <f7-row v-if=" item.tipo === 'groupLayout'">
                        <f7-link
                          popover-open=".popover-menu"
                          @click="selectForm(item.posicion)"
                        >
                          <img
                            :src="iconAddelement"
                            alt="Add"
                          >
                        </f7-link>
                      </f7-row>
                      <f7-link
                        @click="
                          deleteItem2(
                            element.key,
                            results_forms[item.posicion].ref
                          )
                        "
                      >
                        <img
                          width="36px"
                          :src="iconTrash"
                          alt="trash"
                        >
                      </f7-link>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formCreated"
                  class="content-new-group margin-top"
                >
                  <div>
                    <img
                      :src="iconAddGroup"
                      alt="addGroup"
                    >
                    <a
                      class="button"
                      href="#"
                      @click="preAddForm('group')"
                    >Añadir Nuevo Grupo</a>
                  </div>
                </div>
              </f7-col>

              <!-- PREVISUALIZACION -->
              <f7-col
                v-if="formCreated"
                style="background: white"
                :style="variableHeight"
                class="main-preview"
              >
                <hi-forms-visualizer
                  :key="keyTemp"
                  :template="template_out_show"
                  :show-button="false"
                  :execute-services="false"
                />
              </f7-col>
            </f7-row>
          </f7-block>

          <f7-popover class="popover-menu">
            <div class="popover-angle on-top" />
            <f7-list v-if="group_current_type !== 'LIST_TEMPLATE'">
              <f7-list-item
                link="#"
                popover-close
                title="Texto"
                @click="preAddForm('text')"
              />
              <f7-list-item
                link="#"
                popover-close
                title="Número"
                @click="preAddForm('number')"
              />
              <f7-list-item
                link="#"
                popover-close
                title="Lista"
                @click="preAddForm('select')"
              />
              <f7-list-item
                link="#"
                popover-close
                title="Fecha"
                @click="preAddForm('date')"
              />
              <f7-list-item
                link="#"
                popover-close
                title="Fecha y hora"
                @click="preAddForm('datetime')"
              />
              <f7-list-item
                link="#"
                popover-close
                title="Booleano"
                @click="preAddForm('boolean')"
              />
              <f7-list-item
                link="#"
                popover-close
                title="Toggle"
                @click="preAddForm('toggle')"
              />
              <f7-list-item
                link="#"
                popover-close
                title="Archivos Adjuntos"
                @click="preAddForm('file')"
              />
              <f7-list-item
                link="#"
                popover-close
                title="Firma"
                @click="preAddForm('firma')"
              />
              <f7-list-item
                link="#"
                popover-close
                title="Imagen"
                @click="preAddForm('image')"
              />
            </f7-list>
            <f7-list v-else>
              <f7-list-item
                v-if="group_current_type === 'LIST_TEMPLATE'"
                link="#"
                popover-close
                title="Grupo"
                @click="preAddForm('groupLayout')"
              />
            </f7-list>
          </f7-popover>
        </div>
      </div>
      <div
        class="content-details"
      >
        <f7-tabs>
          <f7-tab
            id="tab-1"
            tab-active
          >
            <div class="content-details__header padding">
              <div class="content-details__header--title">
                Propiedades
              </div>
              <div class="content-details__header--options">
                <f7-toolbar tabbar>
                  <f7-link tab-link="#tab-2">
                    <img
                      :src="iconCode"
                      alt="icon"
                    >
                  </f7-link>
                </f7-toolbar>
                <img
                  class="margin-left"
                  :src="iconInfo"
                  alt="info"
                >
              </div>
            </div>
            <div
              v-for="(element, index) in forms"
              :key="index"
            >
              <hi-forms-visualizer
                v-if="index === index_current"
                :template="element"
                :template-data="results_forms[index]"
                :show-button="false"
                :primary="primary"
                :secondary="secondary"
                :font-color-primary="fontColorPrimary"
                :font-color-secondary="fontColorSecondary"
                :btn-text="btnText"
                :btn-round="btnRound"
                :execute-services="false"
                :refresh-data="prevRef?true:false"
                :change-group-type-restrictions="changeGroupTypeRestrictions"
                :bloques="bloques"
                @form-data="demo"
              />
            </div>
          </f7-tab>
          <f7-tab
            id="tab-2"
          >
            <div class="content-details__header padding">
              <div class="content-details__header--title">
                <img
                  :src="iconCoding"
                  alt="code"
                >
                Inspección de código
              </div>
              <div class="content-details__header--options">
                <f7-toolbar tabbar>
                  <f7-link tab-link="#tab-1">
                    <img
                      :src="iconProps"
                      alt="props"
                    >
                  </f7-link>
                </f7-toolbar>
                <img
                  class="margin-left"
                  :src="iconInfo"
                  alt="info"
                >
              </div>
            </div>
            <textarea
              v-model="code"
              class="textarea-json"
            >{{ printFormattedJson }}
            </textarea>
            <f7-row class="justify-content-center margin-top">
              <f7-col width="50">
                <f7-button
                  v-if="!formCreated"
                  fill
                  @click="processCode()"
                >
                  Procesar código pegado
                </f7-button>
              </f7-col>
            </f7-row>
          </f7-tab>
        </f7-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import HiFormsVisualizer from './HiFormsVisualizer.vue';
import icCode from '../../static/img/ic_code.svg';
import icInfo from '../../static/img/ic_info.svg';
import icProps from '../../static/img/ic_props.svg';
import icAddGroup from '../../static/img/ic_add_group.svg';
import icAddElement from '../../static/img/ic_add_element.svg';
import icTrash from '../../static/img/ic_trash_copy.svg';
import icCoding from '../../static/img/ic_coding.svg';

export default {
  name: 'HiFormsBuilder',
  components: { HiFormsVisualizer },
  props: {
    title: { type: String, default: null },
    formsTypes: { type: Array, default: () => [] },
    templateIn: { type: Object, default: () => {} },
    services: { type: Array, default: () => [] },
    entities: { type: Array, default: () => [] },
    changeGroupTypeRestrictions: { type: Boolean, default: false },
  },
  data() {
    return {
      keyTemp: 1,
      windowHeight: window.innerHeight,
      primary: '#434E6F',
      secondary: '#8e8e93',
      fontColorPrimary: '#ffffff',
      fontColorSecondary: '#000000',
      btnText: 'Enviar',
      btnRound: false,
      index_current: 0,
      formCreated: false,
      groupCreated: false,
      group_current: 0,
      item_current: 0,
      group_current_type: '',
      types: [],
      forms: [],
      results_forms: [],
      type_layout: false,
      ref_type_layout: '',
      bloques: [],
      template_out: {
        detail: [],
      },
      template_out_show: {
        detail: [],
      },
      type_current: '',
      counter_current: 0,
      code: '',
      prevRef: '',
      last_group: 0,
      default_position: null,
      data_types: [
        {
          type: 'form',
          options: {
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'name',
                    options: {
                      title: 'Nombre',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'description',
                    options: {
                      title: 'Descripción',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'select',
                    ref: 'entity',
                    options: {
                      filterKey: [
                        'value',
                      ],
                      showKey: 'value',
                      valueKey: 'id',
                      defaultValues: [],
                      title: 'Selecciona entidad',
                      maxSelecteditems: 1,
                      required: false,
                      readOnly: false,
                      calculated: false,
                      selectWithoutButtons: true,
                    },
                    items: [],
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'group',
          options: {
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'name',
                    options: {
                      title: 'Nombre',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'select',
                    ref: 'type',
                    options: {
                      defaultValues: [['GROUP']],
                      placeholder: 'Seleccione un tipo',
                      title: 'Tipo',
                      required: true,
                      readOnly: false,
                      filterKey: ['value'],
                      showKey: 'value',
                      valueKey: 'id',
                      maxSelecteditems: 1,
                    },
                    items: [
                      {
                        id: 'LAYOUT_VERTICAL',
                        value: 'LAYOUT_VERTICAL',
                      },
                      {
                        id: 'LAYOUT_HORIZONTAL',
                        value: 'LAYOUT_HORIZONTAL',
                      },
                      {
                        id: 'GROUP',
                        value: 'GROUP',
                      },
                      {
                        id: 'GRID',
                        value: 'GRID',
                      },
                      {
                        id: 'LIST_TEMPLATE',
                        value: 'LIST_TEMPLATE',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'groupLayout',
          options: {
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'name',
                    options: {
                      title: 'Nombre',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'select',
                    ref: 'type',
                    options: {
                      defaultValues: [['LAYOUT_VERTICAL']],
                      placeholder: 'Seleccione un tipo',
                      title: 'Tipo',
                      required: true,
                      readOnly: false,
                      filterKey: ['value'],
                      showKey: 'value',
                      valueKey: 'id',
                      maxSelecteditems: 1,
                    },
                    items: [
                      {
                        id: 'LAYOUT_VERTICAL',
                        value: 'LAYOUT_VERTICAL',
                      },
                      {
                        id: 'LAYOUT_HORIZONTAL',
                        value: 'LAYOUT_HORIZONTAL',
                      },
                      {
                        id: 'GROUP',
                        value: 'GROUP',
                      },
                      {
                        id: 'GRID',
                        value: 'GRID',
                      },
                      {
                        id: 'LIST_TEMPLATE',
                        value: 'LIST_TEMPLATE',
                      },
                    ],
                  },
                  {
                    dataType: 'select',
                    ref: 'viewType',
                    options: {
                      defaultValues: ['vertical'],
                      placeholder: 'Seleccione un tipo de vista',
                      title: 'Tipo de vista',
                      required: true,
                      readOnly: false,
                      filterKey: ['value'],
                      showKey: 'value',
                      valueKey: 'id',
                      maxSelecteditems: 1,
                    },
                    items: [
                      {
                        id: 'horizontal',
                        value: 'horizontal',
                      },
                      {
                        id: 'vertical',
                        value: 'vertical',
                      },
                      {
                        id: 'grid',
                        value: 'grid',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'text',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 6,
                    ref: 'placeholder',
                    options: {
                      title: 'Placeholder',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readOnly',
                    id: 5,
                    options: {
                      title: 'Sólo lectura',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'textarea',
                    id: 5,
                    options: {
                      title: 'Texto largo / Observaciones',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'password',
                    id: 3,
                    options: {
                      title: 'Password',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },

                  {
                    dataType: 'toggle',
                    ref: 'readQR',
                    id: 5,
                    options: {
                      title: 'QR',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readBarcode',
                    id: 5,
                    options: {
                      title: 'Codigo barras',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'calculated',
                    id: 5,
                    options: {
                      title: 'Calculado',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 6,
                    ref: 'relatedFields',
                    options: {
                      title: 'Campos fórmula',
                      password: false,
                      regExp: null,
                      errorMsg: 'Referencias separadas por coma',
                      defaultValue: '',
                      placeholder: 'referencias separadas por coma',
                      required: false,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 6,
                    ref: 'formula',
                    options: {
                      title: 'Fórmula',
                      password: false,
                      regExp: null,
                      errorMsg: 'error',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: false,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'number',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'number',
                    id: 7,
                    ref: 'defaultValue',
                    options: {
                      title: 'Valor por defecto',
                      regExp: null,
                      errorMsg: 'Solo se admiten números',
                      defaultValue: 0,
                      placeholder: '',
                      stepper: false,
                      required: false,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'number',
                    id: 7,
                    ref: 'min',
                    options: {
                      title: 'Valor mínimo',
                      regExp: null,
                      errorMsg: 'Solo se admiten números',
                      defaultValue: 0,
                      stepper: false,
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'number',
                    id: 7,
                    upperRef: 'listado',
                    ref: 'max',
                    options: {
                      title: 'Valor máximo',
                      regExp: null,
                      errorMsg: 'Solo se admiten números',
                      defaultValue: 99999,
                      placeholder: '',
                      stepper: false,
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'unit',
                    options: {
                      title: 'Unidad de Medida',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readOnly',
                    id: 5,
                    options: {
                      title: 'Sólo lectura',
                      value: false,
                      disabled: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'float',
                    id: 3,
                    options: {
                      title: 'Es decimal',
                      value: false,
                      disabled: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'stepper',
                    id: 3,
                    options: {
                      title: 'Es Stepper',
                      value: false,
                      disabled: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'password',
                    id: 3,
                    options: {
                      title: 'Password',
                      value: false,
                      disabled: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'calculated',
                    id: 5,
                    options: {
                      title: 'Calculado',
                      value: false,
                      disabled: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 6,
                    ref: 'relatedFields',
                    options: {
                      title: 'Campos fórmula',
                      password: false,
                      regExp: null,
                      errorMsg: 'Referencias separadas por coma',
                      defaultValue: '',
                      placeholder: 'referencias separadas por coma',
                      required: false,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 6,
                    ref: 'formula',
                    options: {
                      title: 'Fórmula',
                      password: false,
                      regExp: null,
                      errorMsg: '',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: false,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'select',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'select',
                    ref: 'selectService',
                    options: {
                      filterKey: [
                        'value',
                      ],
                      showKey: 'value',
                      valueKey: 'id',
                      defaultValues: [],
                      title: 'Selecciona Servicio',
                      maxSelecteditems: 1,
                      required: false,
                      readOnly: false,
                      calculated: false,
                      selectWithoutButtons: true,
                    },
                    items: [],
                  },
                  {
                    dataType: 'number',
                    id: 7,
                    ref: 'service_id',
                    options: {
                      title: 'Servicio',
                      regExp: null,
                      errorMsg: 'Solo se admiten números',
                      placeholder: '',
                      required: false,
                      readOnly: false,
                      defaultValue: 0,
                    },
                  },
                  {
                    dataType: 'number',
                    id: 8,
                    ref: 'maxSelecteditems',
                    options: {
                      title: 'Max items seleccionados',
                      regExp: null,
                      errorMsg: 'Solo se admiten números',
                      placeholder: '',
                      required: false,
                      readOnly: false,
                      defaultValue: 1,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readOnly',
                    id: 5,
                    options: {
                      title: 'Sólo lectura',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'calculated',
                    id: 5,
                    options: {
                      title: 'Calculado',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'selectWithoutButtons',
                    id: 7,
                    options: {
                      title: 'No Botón',
                      value: true,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 6,
                    ref: 'relatedFields',
                    options: {
                      title: 'Campos fórmula',
                      password: false,
                      regExp: null,
                      errorMsg: 'Referencias separadas por coma',
                      defaultValue: '',
                      placeholder: 'referencias separadas por coma',
                      required: false,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 6,
                    ref: 'formula',
                    options: {
                      title: 'Fórmula',
                      password: false,
                      regExp: null,
                      errorMsg: 'error',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: false,
                    },
                  },
                ],
              },
              {
                type: 'LIST_TEMPLATE',
                name: 'TITULO',
                ref: 'items',
                z_index: 0,
                children: [
                  {
                    type: 'LAYOUT_VERTICAL',
                    ref: 'layout1',
                    z_index: 0,
                    children: [
                      {
                        dataType: 'number',
                        id: 8,
                        ref: 'id',
                        upperRef: 'items',
                        options: {
                          title: 'Id',
                          regExp: null,
                          errorMsg: 'Solo se admiten números',
                          placeholder: '',
                          required: false,
                          readOnly: false,
                          defaultValue: 1,
                        },
                      },
                      // {
                      //   dataType: 'number',
                      //   id: 1,
                      //   upperRef: 'items',
                      //   ref: 'id',
                      //   options: {
                      //     title: 'Id',
                      //     regExp: null,
                      //     errorMsg: '',
                      //     min: 0,
                      //     max: 999,
                      //     defaultValue: 0,
                      //     placeholder: '',
                      //     stepper: false,
                      //     required: true,
                      //     readOnly: false,
                      //   },
                      // },
                      {
                        dataType: 'text',
                        upperRef: 'items',
                        id: 2,
                        ref: 'value',
                        options: {
                          title: 'Valor',
                          password: false,
                          regExp: null,
                          errorMsg:
                            'the text must be contains bettween 3 and 10 character´s',
                          defaultValue: '',
                          placeholder: 'Escriba un valor aqui',
                          required: true,
                          readOnly: false,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'date',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'select',
                    id: 2,
                    ref: 'dateFormat',
                    options: {
                      title: 'Formato fecha*',
                      errorMsg: 'Campo obligatorio',
                      required: true,
                      readOnly: false,
                      filterKey: ['value'],
                      valueKey: 'id',
                      showKey: 'value',
                      maxSelectedItems: 1,
                      defaultValues: 'dd/mm/yyyy',
                      selectWithoutButtons: true,
                    },
                    items: [
                      {
                        id: 'dd/mm/yyyy',
                        value: 'dia/mes/año',
                      },
                      {
                        id: 'yyyy/mm/dd',
                        value: 'año/mes/dia',
                      },
                    ],
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readOnly',
                    id: 5,
                    options: {
                      title: 'Sólo lectura',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'multiple',
                    id: 4,
                    options: {
                      title: 'Multiple',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'ranged',
                    id: 4,
                    options: {
                      title: 'Tipo Rango',
                      value: false,
                      disabled: false,
                      required: false,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'datetime',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: false,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'select',
                    id: 2,
                    ref: 'dateFormat',
                    options: {
                      title: 'Formato fecha*',
                      errorMsg: 'Campo obligatorio',
                      required: true,
                      readOnly: false,
                      filterKey: ['value'],
                      valueKey: 'id',
                      showKey: 'value',
                      maxSelectedItems: 1,
                      defaultValues: ['dd/mm/yyyy HH::mm'],
                      selectWithoutButtons: true,
                    },
                    items: [
                      {
                        id: 'dd/mm/yyyy HH::mm',
                        value: 'dia/mes/año hora:minuto',
                      },
                      {
                        id: 'yyyy/mm/dd HH::mm',
                        value: 'año/mes/dia hora:minuto',
                      },
                    ],
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readOnly',
                    id: 5,
                    options: {
                      title: 'Sólo lectura',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'boolean',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readOnly',
                    id: 5,
                    options: {
                      title: 'Sólo lectura',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'toggle',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readOnly',
                    id: 5,
                    options: {
                      title: 'Sólo lectura',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'file',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'number',
                    ref: 'maxFiles',
                    options: {
                      title: 'Maximo ficheros',
                      regExp: null,
                      errorMsg: 'Solo se admiten números',
                      defaultValue: 1,
                      min: 1,
                      placeholder: '',
                      stepper: false,
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'multiple',
                    id: 4,
                    options: {
                      title: 'Multiple',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readOnly',
                    id: 5,
                    options: {
                      title: 'Sólo lectura',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'image',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'number',
                    ref: 'maxFiles',
                    options: {
                      title: 'Maximo ficheros',
                      regExp: null,
                      errorMsg: 'Solo se admiten números',
                      defaultValue: 1,
                      min: 1,
                      placeholder: '',
                      stepper: false,
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'multiple',
                    id: 4,
                    options: {
                      title: 'Multiple',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'readOnly',
                    id: 5,
                    options: {
                      title: 'Sólo lectura',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'firma',
          options: {
            id: 1,
            detail: [
              {
                type: 'LAYOUT_VERTICAL',
                z_index: 0,
                children: [
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'refGroup',
                    options: {
                      title: 'Referencia grupo',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'upperRef',
                    options: {
                      title: 'Upper ref',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 1,
                    ref: 'ref',
                    options: {
                      title: 'Referencia',
                      password: false,
                      regExp: null,
                      errorMsg: 'campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'title',
                    options: {
                      title: 'Titulo',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: '',
                      placeholder: '',
                      required: true,
                      readOnly: false,
                    },
                  },
                  {
                    dataType: 'text',
                    id: 2,
                    ref: 'reference',
                    options: {
                      title: 'Reference',
                      password: false,
                      regExp: null,
                      errorMsg: 'Campo obligatorio',
                      defaultValue: 'canvasRef',
                      placeholder: '',
                      required: true,
                      readOnly: true,
                    },
                  },
                  {
                    dataType: 'toggle',
                    ref: 'required',
                    id: 4,
                    options: {
                      title: 'Obligatorio',
                      value: false,
                      disabled: false,
                      required: true,
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    };
  },
  computed: {
    variableHeight() {
      return `max-height:${this.windowHeight - 30}px;overflow-y:scroll`;
    },
    printFormattedJson() {
      this.code = JSON.stringify(this.template_out_show, undefined, 4);
      return JSON.stringify(this.template_out_show, undefined, 4);
    },
    iconCode() {
      return icCode;
    },
    iconCoding() {
      return icCoding;
    },
    iconInfo() {
      return icInfo;
    },
    iconProps() {
      return icProps;
    },
    iconAddGroup() {
      return icAddGroup;
    },
    iconAddelement() {
      return icAddElement;
    },
    iconTrash() {
      return icTrash;
    },
  },
  watch: {
    // template_out_show() {
    //   return this.template_out_show;
    // },
    // template_out() {
    //   return this.template_out;
    // },
    // forms() {
    //   return this.forms;
    // },
    // results_forms() {
    //   return this.results_forms;
    // },
    // templateIn() {
    //   return this.templateIn;
    // },
    // bloques() {
    //   return this.bloques;
    // },
    template_out_show() {
      // Importante para el uso del list_template
      this.keyTemp += 1;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    if (this.formsTypes && this.formsTypes.length > 0) {
      this.data_types = this.formsTypes;
    }

    if (this.templateIn) {
      this.template_out = this.templateIn;
      this.template_out_show = this.templateIn;
      this.parseTemplateIn();
      this.prepareIndexForNextGroup();
    }
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    prepareIndexForNextGroup() {
      let highest = 0;
      this.bloques.forEach((element) => {
        if (element.key.includes('grupo')) {
          const currentIndex = parseInt(element.key.split('grupo').pop());
          if (currentIndex > highest) {
            highest = currentIndex;
          }
        }
      });
      this.last_group = parseInt(highest);
    },
    getClass(index) {
      return {
        '': !index === this.index_current,
        'item-selected-div': index === this.index_current,
      };
    },
    margin(type) {
      if (type === 'form') {
        return 'margin-left:0px';
      }
      if (type === 'group') {
        return 'margin-left:40px';
      }
      return 'margin-left:80px';
    },
    bacgroundBlock(type) {
      if (type === 'groupLayout') {
        return 'background:#777777';
      }
      return '';
    },
    checkIfRefIsAlreadyInUse(refName) {
      const allElements = this.bloques.filter((o) => o.key.includes('grupo')).map((y) => y.items);// get all items from the groups
      const merged = [].concat.apply([], allElements);// create 1 simple array of items;
      const iDsUsed = merged.map(({ referencia }) => referencia);// get only de IDs from the created items
      if (iDsUsed.includes(refName)) {
        return true;
      }
      return false;
    },
    preAddForm(type) {
      this.prevRef = '';
      if (type !== 'form' && type !== 'group') {
        const self = this;
        this.$f7.dialog.prompt('Introduce la referencia del elemento', 'Formularios', (name) => {
          const formattedRefName = name.replace(/[^a-zA-Z0-9]/g, '');
          if (formattedRefName !== '') {
            if (formattedRefName.length > 15) {
              this.$f7.dialog.alert('La referencia de demasiado larga. (Max: 15)', () => {
                self.preAddForm(type);
              });
            } else if (this.checkIfRefIsAlreadyInUse(formattedRefName) === false) {
              self.prevRef = formattedRefName;
              self.addForm(type);
            } else {
              this.$f7.dialog.alert(`La referencia '${formattedRefName}' ya esta en uso`, () => {
                self.preAddForm(type);
              });
            }
          } else {
            this.$f7.dialog.alert('Debe introducir una referencia del elemento', () => {
              self.preAddForm(type);
            });
          }
        });
      }
      if (type === 'form') {
        const self = this;
        this.$f7.dialog.prompt('Introduce el nombre del formulario', 'Formulario', (name) => {
          // UNUSED: View AAC-247 for reference
          const formattedRefName = name.replace(/[^a-zA-Z0-9]/g, '');
          if (formattedRefName !== '') {
            self.addForm(type, null, name);
          } else {
            this.$f7.dialog.alert('Debe introducir un nombre del formulario', () => {
              self.preAddForm(type);
            });
          }
        });
      }
      if (type === 'group') {
        const self = this;
        this.$f7.dialog.prompt('Introduce el nombre del grupo', 'Grupo', (name) => {
          const formattedRefName = name.replace(/[^a-zA-Z0-9]\s/g, '');
          self.addForm(type, formattedRefName);
        });
      }
    },
    addForm(type, groupName = '', formName = '') {
      if (type === 'form') {
        if (this.formCreated) {
          this.$f7.dialog
            .create({
              title: 'Error',
              text: 'El formulario ya está creado',
              buttons: [
                {
                  text: 'Aceptar',
                },
              ],
            })
            .open();
          return;
        }
        this.formCreated = true;
      } else if (type === 'group') {
        this.prevRef = '';
        this.type_layout = false;
        this.ref_type_layout = '';

        if (!this.formCreated) {
          this.$f7.dialog
            .create({
              title: 'Error',
              text: 'Se debe crear primero el formulario',
              buttons: [
                {
                  text: 'Aceptar',
                },
              ],
            })
            .open();
          return;
        }
        this.groupCreated = true;
      } else if (type !== 'form' && type !== 'group') {
        if (!this.formCreated || !this.groupCreated) {
          this.$f7.dialog
            .create({
              title: 'Error',
              text: 'Para crear el campo hay que crear primero un formulario y un grupo',
              buttons: [
                {
                  text: 'Aceptar',
                },
              ],
            })
            .open();
          return;
        }
      }

      const tamplateTemp = this.data_types.find(
        (element) => element.type === type,
      ).options;

      this.index_current = this.counter_current;
      // Caso SELECT. Caso especial, porque al componente se le puede pasar un listado de servicios
      if (type === 'select') {
        console.log('type \'select\'');
        console.log('this.services', this.services);
        if (this.services) {
          const selectService = tamplateTemp.detail[0].children.find((element) => element.ref === 'selectService');
          selectService.items = this.services;
        }
      }
      if (type === 'form') {
        if (this.entities) {
          const selectEntity = tamplateTemp.detail[0].children.find((element) => element.ref === 'entity');
          tamplateTemp.detail[0].children[0].options.defaultValue = formName;
          selectEntity.items = this.entities;
        }
      }
      // const indicePadre = this.group_current;

      if (type !== 'form') {
        if (type === 'group') {
          this.last_group += 1;
          this.group_current = this.last_group;
        }

        // Establezco la referencia por defecto del grupo actual
        tamplateTemp.detail[0].children[0].options.defaultValue = `grupo${this.group_current}`; // ref
        tamplateTemp.detail[0].children[1].options.defaultValue = groupName; // NOMBRE
        if (type === 'groupLayout') {
          tamplateTemp.detail[0].children[1].options.defaultValue = `grupo${this.group_current}`; // upperRef
        }
        if (type === 'group') {
          this.bloques.push({
            key: `grupo${this.group_current}`,
            posicion: this.index_current,
            items: [],
          });
        } else if (type !== 'group' && type !== 'form') {
          // A todos los items les debe establecer el upperREf tambien, debido al list_template
          // A los items solo se les debe establecer upperRef si type_layout=true
          if (this.type_layout) {
            tamplateTemp.detail[0].children[1].options.defaultValue = `grupo${this.group_current}`; // upperRef
          } else {
            tamplateTemp.detail[0].children[1].options.defaultValue = null; // upperRef
          }

          for (let i = 0; i < this.bloques.length; i++) {
            const bloque = this.bloques[i];
            if (bloque.key === `grupo${this.group_current}`) {
              const item = {
                posicion: this.index_current,
                tipo: type,
                form: tamplateTemp,
                referencia: this.prevRef,
                referenciaLayout: this.ref_type_layout,
              };
              bloque.items.push(item);
              this.bloques[i] = bloque;
            }
          }
        }
      } else {
        this.bloques.push({ key: 'form', posicion: 0, items: [] });
      }

      this.counter_current += 1;
      this.type_current = type;

      // this.forms.push(/* this.template_text */ tamplateTemp);
      if (this.type_layout) {
        // this.results_forms.push({ isChildrenLayout: true });

        tamplateTemp.detail[0].children[2].options.defaultValue = this.prevRef;
        const tmpData = { ref: this.prevRef, isChildrenLayout: true };
        this.results_forms.push(tmpData);
      } else if (this.prevRef && this.type_current !== 'form' && this.type_current !== 'group') {
        this.group_current_type = '';
        tamplateTemp.detail[0].children[2].options.defaultValue = this.prevRef;
        const tmpData = { ref: this.prevRef };
        this.results_forms.push(tmpData);
      } else {
        this.results_forms.push({});
      }

      this.forms.push(/* this.template_text */ tamplateTemp);

      this.types.push(this.type_current);

      this.reorderBloques();
    },
    reorderBloques() {
      if (this.bloques) {
        for (let i = 0; i < this.bloques.length; i++) {
          const itm = this.bloques[i];

          if (itm.key !== 'form') {
            const itemsReorder = [];
            const { items } = itm;
            const grLayouts = items.filter((element) => element.tipo === 'groupLayout');
            if (grLayouts.length > 0) {
              // Busco los items que pertenecen al groupLayout
              for (let j = 0; j < grLayouts.length; j++) {
                // console.log("Busco los hijos buscando por " + grLayouts[j].referencia);
                const itmsHijos = items.filter((element) => element.referenciaLayout === grLayouts[j].referencia);

                itemsReorder.push(grLayouts[j]);
                itemsReorder.push(...itmsHijos);
              }
              this.bloques[i].items = itemsReorder;
            }
          }
        }
      }
    },
    selectForm(index) {
      this.prevRef = '';
      // Necesario para que al seleccionar un bloque no
      // actualice formVisualizer y duplique el bloque

      this.group_current_type = '';
      // console.log(JSON.stringify(this.template_out_show, undefined, 4));
      this.index_current = index;
      this.type_current = this.types[index];

      // nuevo enrique
      if (this.type_current === 'select') {
        if (this.services) {
          const tamplateTemp = this.data_types.find(
            (element) => element.type === this.type_current,
          ).options;
          const selectService = tamplateTemp.detail[0].children.find((element) => element.ref === 'selectService');
          selectService.items = this.services;
        }
      }
      //Fin nuevo enrique

      if (this.type_current === 'group') {
        if (this.results_forms[this.index_current].ref) {
          this.group_current = this.results_forms[this.index_current].ref.replace(
            'grupo',
            '',
          );
        }

        this.group_current_type = this.results_forms[this.index_current].type;
      }

      if (this.type_current === 'groupLayout') {
        if (this.results_forms && this.results_forms[this.index_current]) {
          this.type_layout = true;
          this.ref_type_layout = this.results_forms[this.index_current].ref;
          // Actualizar el grupo al que pertenece (group_current)
          this.group_current = this.results_forms[this.index_current].refGroup.replace(
            'grupo',
            '',
          );
        }
      } else if (this.results_forms && this.results_forms[this.index_current]) {
        this.type_layout = false;
        this.ref_type_layout = '';
        // Actualizar el grupo al que pertenece (group_current)
        if (this.type_current !== 'group' && this.type_current !== 'form') {
          this.group_current = this.results_forms[this.index_current].refGroup.replace(
            'grupo',
            '',
          );

          if (this.results_forms[this.index_current].upperRef) {
            // Para los que son hijos de groupLayout, es necesario establecer las dos siguientes
            // variables, para que asi el componente lo trate de form especifica
            this.type_layout = true;
            this.ref_type_layout = this
              .getRefGroupLayout(this.results_forms[this.index_current].ref);
          }
        }
      }
    },
    getRefGroupLayout(refHijo) {
      for (let i = 0; i < this.bloques.length; i++) {
        const tmp = this.bloques[i];
        if (tmp.key !== 'form') {
          const itms = tmp.items;
          const encontrado = itms.find((element) => element.referencia === refHijo);
          if (encontrado) {
            return encontrado.referenciaLayout;
          }
        }
      }
      return '';
    },
    demo({ data }) {
      // La referencia nunca puede contener espacios
      if (data.ref) {
        data.ref = data.ref.replaceAll(' ', '');
      }

      this.group_current_type = '';
      this.results_forms[this.index_current] = data;

      if (this.type_current === 'form') {
        for (const [key, value] of Object.entries(data)) {
          this.template_out[key] = value;
        }
      } else if (this.type_current === 'group') {
        const detalles = this.template_out.detail;
        if (detalles.length > 0) {
          let detalle = detalles.find((element) => element.ref === data.ref);

          if (!detalle) {
            detalle = {};
            for (const [key, value] of Object.entries(data)) {
              try {
                detalle[key] = value;
                if (key === 'type') {
                  this.group_current_type = value;
                }
              } catch (e) {
                /* console.log(e); */
              }
            }
            detalle.children = [];
            this.template_out.detail.push(detalle);
          } else {
            for (const [key, value] of Object.entries(data)) {
              try {
                detalle[key] = value;
                if (key === 'type') {
                  this.group_current_type = value;
                }
              } catch (e) {
                /* console.log(e); */
              }
            }

            if (!detalle.children) {
              detalle.children = [];
            }
            if (detalle.type === 'LIST_TEMPLATE') {
              console.log('!!! es LIST_TEMPLATE !!!!!');
            }
            this.template_out.detail[detalle.ref] = detalle;
          }
        } else if (data.ref !== 0) {
          const detalle = {};
          for (const [key, value] of Object.entries(data)) {
            try {
              detalle[key] = value;
              if (key === 'type') {
                this.group_current_type = value;
              }
            } catch (e) {
              /* console.log(e); */
            }
          }
          if (!detalle.children) {
            detalle.children = [];
          }
          this.template_out.detail.push(detalle);
        } else {
          console.log('Aun no hay ref');
        }
      } else {
        const detalles = this.template_out.detail;

        // Detalle del grupo a partir de su ref
        const detalle = detalles.find(
          (element) => element.ref === data.refGroup,
        );

        // Detalle del layoutGroup si existe type_layout y ref_type_layout
        let groupLayoutTemp = {};
        if (this.type_layout && this.ref_type_layout) {
          groupLayoutTemp = detalle.children.find(
            (element) => element.ref === this.ref_type_layout,
          ) || {};
        }

        // Existe grupo
        if (detalle) {
          // El grupo tiene array de hijos
          if (detalle.children) {
            let children = {};
            children = detalle.children.find(
              (element) => element.ref === data.ref,
            );
            if (children) {
              // console.log('CASO 1.........');
              if (this.type_current === 'groupLayout') {
                if (this.type_layout) {
                  children.upperRef = data.refGroup;
                }
                children.type = data.type;
                children.z_index = data.z_index;
                children.name = data.name;
                children.viewType = data.viewType;
                // children.children = [];
              } else {
                children.options = {};
              }

              if (this.type_current === 'select') {
                children.items = [];
                children.options.filterKey = ['value'];
                children.options.showKey = 'value';
                children.options.valueKey = 'id';
                children.options.defaultValues = [];
                children.options.maxSelectedItems = 1;
              } else if (
                this.type_current === 'date'
                || this.type_current === 'datetime'
              ) {
                children.options.datetype = {};
                children.options.datetype.multiple = false;
                children.options.datetype.range = {};
                children.options.datetype.range.min = 0;
                children.options.datetype.range.max = 1;
              }

              const calcOptionsTemp = {};
              calcOptionsTemp.relatedFields = [];
              calcOptionsTemp.formula = '';
              for (const [key, value] of Object.entries(data)) {
                try {
                  if (key === 'items') {
                    if (value && value.length > 0) {
                      for (let cont = 0; cont < value.length; cont++) {
                        children.items.push(value[cont]);
                      }
                      // children.items.push(value);
                    }
                  } else {
                    // Control servicios

                    if (key === 'selectService' && value) {
                      children.options.service_id = parseInt(value, 10);
                      // Nuevo enrique
                      children.options.selectService = this.services;
                      // Fin nuevo enrique
                    }
                    // Fin control servicios

                    // children.options[key] = value;

                    // Control de formulas
                    if (key === 'formula') {
                      calcOptionsTemp.formula = value;
                    } else if (key === 'relatedFields') {
                      let valueTemp = value;
                      valueTemp = valueTemp.trim();
                      calcOptionsTemp.relatedFields = valueTemp.split(',');
                    } else if (key === 'viewType') {
                      children[key] = value[0];
                    }

                    children.options[key] = value;
                  }
                } catch (e) {
                  // console.log(e);
                }
              }
              // Se establece las opciones de formula
              //if (this.type_current === 'select' || this.type_current === 'number' || this.type_current === 'text') {
                if(children.options /*&& children.options.calcOptions*/) {
                  children.options.calcOptions = calcOptionsTemp;
                }

              //}

              // ===========================================================
              if (this.type_layout && this.ref_type_layout) {
                const existe = detalle.children.find(
                  (element) => element.ref === this.ref_type_layout,
                );
                if (existe) {
                  groupLayoutTemp.children[this.ref_type_layout] = children;
                } else {
                  // eslint-disable-next-line no-unused-expressions
                  groupLayoutTemp.children && groupLayoutTemp.children.push(children);
                }
                detalle.children[children.ref] = groupLayoutTemp;
              } else {
                detalle.children[children.ref] = children;
              }
              // ============================================================
            } else if (data.ref !== 0) {
              // console.log('CASO 2.........');
              children = {};
              children.dataType = this.type_current;
              children.ref = data.ref;
              children.refGroup = data.refGroup;
              if (this.type_layout) {
                children.upperRef = data.refGroup;
              }
              if (this.type_current === 'groupLayout') {
                if (this.type_layout) {
                  children.upperRef = data.refGroup;
                }
                children.type = data.type;
                children.z_index = data.z_index;
                children.name = data.name;
                children.children = [];
              } else {
                children.options = {};
              }

              if (this.type_current === 'select') {
                children.items = [];
                children.options.filterKey = ['value'];
                children.options.showKey = 'value';
                children.options.valueKey = 'id';
                children.options.defaultValues = [];
                children.options.maxSelectedItems = 1;
              } else if (
                this.type_current === 'date'
                || this.type_current === 'datetime'
              ) {
                children.options.datetype = {};
                children.options.datetype.multiple = false;
                children.options.datetype.range = {};
                children.options.datetype.range.min = 0;
                children.options.datetype.range.max = 1;
              }

              const calcOptionsTemp = {};
              calcOptionsTemp.relatedFields = [];
              calcOptionsTemp.formula = '';

              for (const [key, value] of Object.entries(data)) {
                try {
                  if (key === 'items') {
                    if (value && value.length > 0) {
                      for (let cont = 0; cont < value.length; cont++) {
                        children.items.push(value[cont]);
                      }
                      // children.items.push(value);
                    }
                  } else {
                    children.options[key] = value;

                    // Control de formulas
                    if (key === 'formula') {
                      calcOptionsTemp.formula = value;
                    } else if (key === 'relatedFields') {
                      let valueTemp = value;
                      valueTemp = valueTemp.trim();
                      calcOptionsTemp.relatedFields = valueTemp.split(',');
                    }
                  }
                } catch (e) {
                  /*  console.log(e); */
                }
              }
              //if (this.type_current === 'select' || this.type_current === 'number' || this.type_current === 'text') {
                if(children.options /*&& children.options.calcOptions*/) {
                  children.options.calcOptions = calcOptionsTemp;
                }
              //}

              if (!this.ref_type_layout) {
                detalle.children.push(children);
              } else {
                // console.log(groupLayoutTemp);
                const existe = groupLayoutTemp.children && groupLayoutTemp.children.find(
                  (element) => element.ref === data.ref,
                );

                if (existe) {
                  // groupLayoutTemp.children[this.ref_type_layout] = children;
                  const indx = groupLayoutTemp.children.findIndex(
                    (element) => element.ref === data.ref,
                  );
                  groupLayoutTemp.children[indx] = children;
                } else {
                  // eslint-disable-next-line no-unused-expressions
                  groupLayoutTemp.children && groupLayoutTemp.children.push(children);
                }
                detalle.children[children.ref] = groupLayoutTemp;
              }
            }
          }
          this.template_out.detail[detalle.id] = detalle;
        }
      }

      this.removeIncorrectListTemplateChildren();

      // this.reorderListTemplates();
      this.template_out_show = {};
      this.template_out_show = this.template_out;
      // console.log(JSON.stringify(this.template_out_show, undefined, 4));

      this.data = {};

      this.$emit('form-data-builder', { data: this.template_out_show });
      this.$emit('form-forms', { data: this.forms });
      this.$emit('form-result-forms', { data: this.results_forms });
    },
    removeIncorrectListTemplateChildren() {
      if (!this.template_out || !this.template_out.detail) return;
      for (let i = 0; i < this.template_out.detail.length; i += 1) {
        const detail = this.template_out.detail[i];
        if (!detail) return;
        const isAListTemplate = detail.type === 'LIST_TEMPLATE';
        // Remove all items with a dataType !== groupLayout
        if (isAListTemplate && detail.children) {
          const lastListTemplateChild = detail.children[detail.children.length - 1];
          if (!lastListTemplateChild) return;
          const correctChildren = detail.children.filter((groupLayout) => groupLayout.dataType === 'groupLayout');
          if (correctChildren && correctChildren.length) {
            this.template_out.detail[i].children = correctChildren;
            const newDetail = this.template_out.detail[i];
            const children = newDetail.children[newDetail.children.length - 1];
            if (!children) return;
            const badElementIndex = children.children.findIndex(
              (ele) => ele.ref === lastListTemplateChild.ref,
            );
            if (badElementIndex >= 0) {
              lastListTemplateChild.upperRef = this.template_out.detail[i]
                .children[this.template_out.detail[i].children.length - 1]
                .children[badElementIndex].refGroup;
              this.template_out.detail[i].children[this.template_out.detail[i].children.length - 1]
                .children[badElementIndex] = lastListTemplateChild;
            }
          }
        }
      }
    },
    parseTemplateIn() {
      this.bloques = [];
      // Partimos del template_out cargado de la propiedad templateIn
      // 1. formulario del formulario
      const formForm = this.data_types.find(
        (element) => element.type === 'form',
      ).options;
      this.index_current = this.counter_current;
      this.counter_current += 1;
      this.type_current = 'form';
      // 1.1. Añado la plantilla del formulario del formulario
      this.forms.push(formForm);
      this.bloques.push({ key: 'form', posicion: 0, items: [] });
      this.results_forms.push({
        name: this.template_out.name,
        description: this.template_out.description,
        entity: this.template_out.entity,
      });
      this.types.push(this.type_current);
      this.formCreated = true;

      // Para que en la edicion el select de entidades asociadas al formulario aparezcan
      const tamplateTemp = this.data_types.find(
        (element) => element.type === 'form',
      ).options;
      const selectEntity = tamplateTemp.detail[0].children.find((element) => element.ref === 'entity');
      selectEntity.items = this.entities;

      // 2. Recorro los grupos existentes
      const formGroup = this.data_types.find(
        (element) => element.type === 'group',
      ).options;
      const grupos = this.template_out.detail;
      for (let i = 0; i < grupos.length; i++) {
        const grupo = grupos[i];
        // 2.1. Añado el grupo
        this.index_current = this.counter_current;
        this.counter_current += 1;
        this.type_current = 'group';
        this.forms.push(formGroup);
        this.results_forms.push({
          type: grupo.type,
          z_index: grupo.z_index,
          ref: grupo.ref,
          name: grupo.name,
          calculated: grupo.calculated,
          formula: grupo.formula,
          relatedFields: grupo.relatedFields,
          visibilidad: grupo.visibilidad
        });
        this.types.push(this.type_current);
        this.groupCreated = true;
        this.group_current += 1;

        // bloque
        const bloque = {
          key: grupo.ref,
          posicion: this.index_current,
          items: [],
        };
        // fin bloque

        // 2.2. Obtengo todos los campos y los añado
        const campos = grupo.children;
        for (let j = 0; j < campos.length; j++) {
          // eslint-disable-next-line no-continue
          if (!campos[j]) continue;
          const campo = campos[j];
          // 2.2.1. Añado el campo
          this.index_current = this.counter_current;
          this.counter_current += 1;
          this.type_current = campo.dataType;
          let formCampo = null;
          const existsFormCampo = this.data_types.find(
            (element) => element.type === campo.dataType,
          );
          if (existsFormCampo) {
            formCampo = this.data_types.find(
              (element) => element.type === campo.dataType,
            ).options;
          }
          this.forms.push(formCampo);

          if (this.type_current === 'groupLayout') {
            this.results_forms.push(campo);
          } else if (this.type_current === 'select') {
            // Añadido para que se puedan mostrar las opciones al editar
            campo.options.items = campo.items;
            this.results_forms.push(campo.options);
          } else {
            this.results_forms.push(campo.options);
          }
          this.types.push(this.type_current);

          // bloques
          const item = {
            posicion: this.index_current,
            tipo: campo.dataType,
            form: formCampo,
            referencia: campo.ref,
            referenciaLayout: '',
          };
          bloque.items.push(item);
          // fin bloques

          // control LIST_LAYOUT y sus groupLayout //
          if (this.type_current === 'groupLayout') {
            // console.log("!!!!!!!!!! ENTRA en el bucle");
            // recorro los children del campo
            for (let k = 0; k < campo.children.length; k++) {
              this.index_current = this.counter_current;
              this.counter_current += 1;

              const layout = campo.children[k];

              this.type_current = layout.dataType;
              // console.log("tipo-->" + this.type_current);

              const formCampo1 = this.data_types.find(
                (element) => element.type === layout.dataType,
              ).options;
              this.forms.push(formCampo1);
              this.types.push(this.type_current);
              let tmp = layout.options;
              if(layout.items) {
                tmp.items = layout.items;
              }
              this.results_forms.push(tmp);
              // this.results_forms.push(layout.options);

              const item1 = {
                posicion: this.index_current,
                tipo: this.type_current,
                form: formCampo1,
                referencia: layout.ref,
                referenciaLayout: campo.ref,
              };
              bloque.items.push(item1);
            }
            // console.log("!!!!!!!!!! SALE en el bucle");
          }
          // /////////////////////////////////////////
        }

        this.bloques.push(bloque);

        if (this.default_position) {
          this.index_current = this.default_position;
          // this.default_position = null;
        }
      }
    },
    deleteItem(grupo, ref) {
      // 1. Busco el grupo
      const grupoIdent = this.template_out.detail.find(
        (element) => element.ref === grupo,
      );

      // 2. Busco el children del grupo
      // const itemIdent = grupoIdent.children.find((element) => element.ref === ref);

      for (let i = 0; i < grupoIdent.children.length; i++) {
        if (grupoIdent.children[i].ref === ref) {
          grupoIdent.children.splice(i, 1);
          // this.deleteItemFormResult(grupo, ref);

          this.template_out_show = this.template_out;

          // Reseteo
          this.index_current = 0;
          this.counter_current = 0;
          this.group_current = 0;
          this.item_current = 0;
          this.results_forms = [];
          this.types = [];
          this.forms = [];

          this.parseTemplateIn();
          break;
        }
      }
    },
    deleteItem2(grupo, ref) {
      // let deleteTemp = false;

      // let position = -1;
      // let positionGroup = -1;
      this.$f7.dialog.confirm(`¿Seguro que desea eliminar este elemento "#${ref}"?`, async () => {
        let template_temporal = {
          detail: [],
        };
        if (this.template_out) {
          template_temporal = this.template_out;

          for (let i = 0; i < template_temporal.detail.length; i++) {
            if (template_temporal.detail[i].ref === grupo) {
              for (
                let j = 0;
                j < template_temporal.detail[i].children.length;
                j++
              ) {
                const typeTemp = template_temporal.detail[i].children[j].type;
                let type1 = '';
                if (Array.isArray(typeTemp)) {
                  type1 = typeTemp[0][0];
                } else {
                  type1 = typeTemp;
                }

                if (type1 !== 'LAYOUT_VERTICAL' && type1 !== 'LAYOUT_HORIZONTAL' && type1 !== 'GROUP' && type1 !== 'GRID') {
                  if (template_temporal.detail[i].children[j].ref === ref) {
                    template_temporal.detail[i].children.splice(j, 1);

                    // deleteTemp = true;

                    // Reseteo
                    this.index_current = 0;
                    this.counter_current = 0;
                    this.group_current = 0;
                    this.item_current = 0;
                    this.results_forms = [];
                    this.types = [];
                    this.forms = [];

                    this.parseTemplateIn();
                    this.keyTemp += 1;
                    break;
                  }
                } else {
                  // Caso item dentro de un groupLayout

                  if (template_temporal.detail[i].children[j].ref === ref) {
                    template_temporal.detail[i].children.splice(j, 1);
                    // Reseteo
                    this.index_current = 0;
                    this.counter_current = 0;
                    this.group_current = 0;
                    this.item_current = 0;
                    this.results_forms = [];
                    this.types = [];
                    this.forms = [];

                    this.parseTemplateIn();
                    this.keyTemp += 1;
                  }

                  if (template_temporal.detail[i].children[j]) {
                    for (
                      let k = 0;
                      k < template_temporal.detail[i].children[j].children.length;
                      k++
                    ) {
                      if (template_temporal.detail[i].children[j].children[k].ref === ref) {
                        template_temporal.detail[i].children[j].children.splice(k, 1);

                        // deleteTemp = true;

                        // Reseteo
                        this.index_current = 0;
                        this.counter_current = 0;
                        this.group_current = 0;
                        this.item_current = 0;
                        this.results_forms = [];
                        this.types = [];
                        this.forms = [];

                        this.parseTemplateIn();
                        this.keyTemp += 1;
                        break;
                      }
                    }
                  }
                }
              }
            }
          }

          // console.log('SE debe actualizar template_out');
          this.template_out = {};
          this.template_out = template_temporal;
          this.template_out_show = {};
          this.template_out_show = this.template_out;

          this.$emit('form-data-builder', { data: this.template_out_show });
          this.$emit('form-forms', { data: this.forms });
          this.$emit('form-result-forms', { data: this.results_forms });
        }
      });
    },
    goUpGroup(grupo, ref) {
      let positionNew = 0;
      if(this.template_out) {
        const template_temporal = this.template_out;
        for (let i = 0; i < template_temporal.detail.length; i++) {
          if (template_temporal.detail[i].ref === grupo) {
            if((i-1) >= 0) {
              const first = template_temporal.detail[i - 1];
              const refFirst = first.ref;
              const bloquetmp = this.bloques.find((e) => e.key === refFirst)
              positionNew = bloquetmp.posicion;
              const second = template_temporal.detail[i];

              template_temporal.detail[i - 1] = second;
              template_temporal.detail[i] = first;

              this.default_position = positionNew;
            }
            // Reseteo
            this.index_current = 0;
            this.counter_current = 0;
            this.group_current = 0;
            this.item_current = 0;
            this.results_forms = [];
            this.types = [];
            this.forms = [];

            this.parseTemplateIn();
            this.keyTemp += 1;
            break;
          }
        }
        this.template_out = {};
        this.template_out = template_temporal;
        this.template_out_show = {};
        this.template_out_show = this.template_out;

        this.$emit('form-data-builder', { data: this.template_out_show });
        this.$emit('form-forms', { data: this.forms });
        this.$emit('form-result-forms', { data: this.results_forms });
        this.selectForm(positionNew);
      }
    },
    goDownGroup(grupo, ref) {
      let positionNew = 0;
      if(this.template_out) {
        const template_temporal = this.template_out;
        for (let i = 0; i < template_temporal.detail.length; i++) {
          if (template_temporal.detail[i].ref === grupo) {
            if((i+1) < template_temporal.detail.length) {
              const first = template_temporal.detail[i+1];
              const refFirst = first.ref;
              const bloquetmp = this.bloques.find((e) => e.key === refFirst)
              positionNew = bloquetmp.posicion;
              const second = template_temporal.detail[i];

              template_temporal.detail[i+1] = second;
              template_temporal.detail[i] = first;

              this.default_position = positionNew;
            }
            // Reseteo
            this.index_current = 0;
            this.counter_current = 0;
            this.group_current = 0;
            this.item_current = 0;
            this.results_forms = [];
            this.types = [];
            this.forms = [];

            this.parseTemplateIn();
            this.keyTemp += 1;
            break;
          }
        }
        this.template_out = {};
        this.template_out = template_temporal;
        this.template_out_show = {};
        this.template_out_show = this.template_out;

        this.$emit('form-data-builder', { data: this.template_out_show });
        this.$emit('form-forms', { data: this.forms });
        this.$emit('form-result-forms', { data: this.results_forms });
        this.selectForm(positionNew)
      }
    },
    goUp(grupo, ref) {
      if (this.template_out) {
        const template_temporal = this.template_out;

        for (let i = 0; i < template_temporal.detail.length; i++) {
          if (template_temporal.detail[i].ref === grupo) {
            if (template_temporal.detail[i].type !== 'LIST_TEMPLATE') {
              for (
                let j = 0;
                j < template_temporal.detail[i].children.length;
                j++
              ) {
                if (template_temporal.detail[i].children[j].ref === ref) {
                  // Este es el que hay que mover

                  if ((j - 1) >= 0) {
                    const first = template_temporal.detail[i].children[j - 1];
                    const second = template_temporal.detail[i].children[j];

                    template_temporal.detail[i].children[j - 1] = second;
                    template_temporal.detail[i].children[j] = first;
                    this.index_current -= 1;

                    this.default_position = this.index_current;
                  }

                  // Reseteo
                  this.index_current = 0;
                  this.counter_current = 0;
                  this.group_current = 0;
                  this.item_current = 0;
                  this.results_forms = [];
                  this.types = [];
                  this.forms = [];

                  this.parseTemplateIn();
                  this.keyTemp += 1;
                  break;
                }
              }
            } else {
              for (
                let j = 0;
                j < template_temporal.detail[i].children.length;
                j++
              ) {
                for (let k = 0; k < template_temporal.detail[i].children[j].children.length; k++) {
                  if (template_temporal.detail[i].children[j].children[k].ref === ref) {
                    if ((k - 1) >= 0) {
                      const first = template_temporal.detail[i].children[j].children[k - 1];
                      const second = template_temporal.detail[i].children[j].children[k];

                      template_temporal.detail[i].children[j].children[k - 1] = second;
                      template_temporal.detail[i].children[j].children[k] = first;
                      this.index_current -= 1;

                      this.default_position = this.index_current;
                    }

                    // Reseteo
                    this.index_current = 0;
                    this.counter_current = 0;
                    this.group_current = 0;
                    this.item_current = 0;
                    this.results_forms = [];
                    this.types = [];
                    this.forms = [];

                    this.parseTemplateIn();
                    this.keyTemp += 1;
                    break;
                  }
                }
              }
            }
          }
        }

        // console.log('SE debe actualizar template_out');
        this.template_out = {};
        this.template_out = template_temporal;
        this.template_out_show = {};
        this.template_out_show = this.template_out;

        this.$emit('form-data-builder', { data: this.template_out_show });
        this.$emit('form-forms', { data: this.forms });
        this.$emit('form-result-forms', { data: this.results_forms });
      }
    },
    goDown(grupo, ref) {
      if (this.template_out) {
        const template_temporal = this.template_out;

        for (let i = 0; i < template_temporal.detail.length; i++) {
          if (template_temporal.detail[i].ref === grupo) {
            if (template_temporal.detail[i].type !== 'LIST_TEMPLATE') {
              for (
                let j = 0;
                j < template_temporal.detail[i].children.length;
                j++
              ) {
                if (template_temporal.detail[i].children[j].ref === ref) {
                  // Este es el que hay que mover

                  if ((j + 1) < template_temporal.detail[i].children.length) {
                    const first = template_temporal.detail[i].children[j + 1];
                    const second = template_temporal.detail[i].children[j];

                    template_temporal.detail[i].children[j + 1] = second;
                    template_temporal.detail[i].children[j] = first;
                    this.index_current += 1;

                    this.default_position = this.index_current;
                  }

                  // Reseteo
                  this.index_current = 0;
                  this.counter_current = 0;
                  this.group_current = 0;
                  this.item_current = 0;
                  this.results_forms = [];
                  this.types = [];
                  this.forms = [];

                  this.parseTemplateIn();
                  this.keyTemp += 1;
                  break;
                }
              }
            } else {
              for (
                let j = 0;
                j < template_temporal.detail[i].children.length;
                j++
              ) {
                for (let k = 0; k < template_temporal.detail[i].children[j].children.length; k++) {
                  if (template_temporal.detail[i].children[j].children[k].ref === ref) {
                  // Este es el que hay que mover

                    if ((k + 1) < template_temporal.detail[i].children[j].children.length) {
                      const first = template_temporal.detail[i].children[j].children[k + 1];
                      const second = template_temporal.detail[i].children[j].children[k];

                      template_temporal.detail[i].children[j].children[k + 1] = second;
                      template_temporal.detail[i].children[j].children[k] = first;
                      this.index_current += 1;

                      this.default_position = this.index_current;
                    }

                    // Reseteo
                    this.index_current = 0;
                    this.counter_current = 0;
                    this.group_current = 0;
                    this.item_current = 0;
                    this.results_forms = [];
                    this.types = [];
                    this.forms = [];

                    this.parseTemplateIn();
                    this.keyTemp += 1;
                    break;
                  }
                }
              }
            }
          }
        }

        // console.log('SE debe actualizar template_out');
        this.template_out = {};
        this.template_out = template_temporal;
        this.template_out_show = {};
        this.template_out_show = this.template_out;

        this.$emit('form-data-builder', { data: this.template_out_show });
        this.$emit('form-forms', { data: this.forms });
        this.$emit('form-result-forms', { data: this.results_forms });
      }
    },
    promptDeleteGroup(grupo) {
      if (grupo.includes('form')) {
        this.$f7.dialog.confirm('¿Seguro que desea eliminar el formulario completo ?', async () => {
          this.template_out.detail.forEach((element) => {
            this.deleteGroupByName(element.ref);
          });
        });
      } else {
        this.$f7.dialog.confirm(`¿Seguro que desea eliminar este grupo "#${grupo}"?`, async () => {
          this.deleteGroupByName(grupo);
        });
      }
    },
    deleteGroupByName(groupName) {
      if (this.template_out) {
        this.template_out.detail = this
          .template_out.detail.filter((group) => group.ref !== groupName);
        this.index_current = 0;
        this.counter_current = 0;
        this.group_current = 0;
        this.item_current = 0;
        this.results_forms = [];
        this.types = [];
        this.forms = [];
        this.parseTemplateIn();
        this.keyTemp += 1;
        this.$emit('form-data-builder', { data: this.template_out.detail });
        this.$emit('form-forms', { data: this.forms });
        this.$emit('form-result-forms', { data: this.results_forms });
      }
    },
    processCode() {
      this.templateIn = JSON.parse(this.code);

      this.template_out = this.templateIn;
      this.template_out_show = this.templateIn;

      // Reseteo
      this.index_current = 0;
      this.counter_current = 0;
      this.group_current = 0;
      this.item_current = 0;
      this.results_forms = [];
      this.types = [];
      this.forms = [];

      this.parseTemplateIn();

      this.$emit('form-data-builder', { data: this.template_out_show });
      this.$emit('form-forms', { data: this.forms });
      this.$emit('form-result-forms', { data: this.results_forms });
    },
  },
};
</script>

<style lang="scss">

.input-level{
  display: flex;
  &__container{
    display: flex;
    justify-items: center;
    align-items: center;
    margin-right:5px;
    font-size:12px;
  }
  &__label{
    background-color: #e9e3f2;
    padding:5px;
    border-radius:3px;
  }
}
.group-buttons__container{
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-left:5px;
  justify-content:center;
}

.flexSpaceAround{
  display:flex;
  justify-content:space-around;
}
.fullWidth{
  width:100%;
}
.item-selected-div {
  border: 1px solid green;
}
.textarea-json {
  width: 100%;
  min-height: 30rem;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 0.8rem;
  line-height: 1.2;
  background-color: black;
  color: white;
  border-radius: 0;
}

.main-hiformsbuilder {
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content-formsbuilder {
    display: flex;
    align-items: center;
  }
  .content-builder {
    flex-grow: 4;
    background: #f5f5f7;
    min-height: 100vh;
    .margin-group{
      margin-left: 0;
    }
    .content-group {
      display: flex;
      .content-group-controls {
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        &__position {
          background-color: #e9e3f2;
          width: 60px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          border-radius: 3px;
          margin-bottom: 10px;
        }
        &__button{
          width: 24px;
          height: 24px;
        }
      }
      .content-group-data {
        flex-grow: 1;
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #ebf2f7;
        border-radius: 3px;
        padding: 15px;
        &--selected {
          border: 1px solid #0a5fff;
        }
        &__ref {
          color: #a5a9b8;
          display: flex;
          justify-content: flex-end;
          font-size: 10px;
          .content-add-element{
            margin-left: 0;
          }
        }
      }
    }
    .content-group-child {
      display: flex;
      .content-group-child-controls {
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        &__position {
          background-color: #e9e3f2;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          border-radius: 3px;
          margin-bottom: 10px;
        }
      }
      .content-group-child-item {
        flex-grow: 1;
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #ebf2f7;
        border-radius: 3px;
        padding: 4px 6px 15px 15px;
        min-height: 68px;
        &--selected {
          border: 1px solid #0a5fff;
        }
        &__ref {
          color: #a5a9b8;
          display: flex;
          justify-content: flex-end;
          font-size: 10px;
        }
      }
    }
  }
  .content-details {
    flex-grow: 1;
    height: 100vh;
    overflow: scroll;
    align-self: flex-start;
    min-width: 400px;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ebf2f7;
      &--title {
        color: var(--his-blue);
        font-size: 16px;
        font-weight: 500;
      }
      &--options {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .toolbar {
          width: 24px;
          height: 24px;
          background: transparent;
          padding: 0;
          margin: 0;
          .toolbar-inner {
            a {
              width: 24px;
              background-color: #ffffff;
              min-width: 24px !important;
              height: 24px;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }
  .content-new-group {
    div {
      display: flex;
      align-items: center;
      background-color: #efecf4;
      padding: 0 15px;
      width: fit-content;
      border-radius: 3px;
      img {
        width: 14px;
        height: 14px;
      }
      a {
        font-size: 12px;
        color: #77869e;
        background-color: transparent;
      }
    }
  }
  .content-add-element {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgba(250, 251, 253, 0.6);
    border: 1px dashed #d5dce0;
    margin-left: 80px;
    a {
      width: 34px;
      height: 34px;
      padding: 0;
      margin: 0;
      background-color: transparent;
    }
  }
  .content-add-sub-element{
    margin-left: 40px;
  }
}
.layout-title{
  padding: calc(var(--f7-typography-padding) / 2) 0 calc(var(--f7-typography-padding) / 2) calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));
  margin-top: calc(var(--f7-typography-margin) / 2) !important;
  margin-bottom: calc(var(--f7-typography-margin) / 2) !important;
  letter-spacing: 0.02em;
  background: #f7f8fa;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
}
.content-details{
  .layout-title{
    padding: 0;
    height: 0;
  }
}
.ml-80{
  margin-left: 120px!important;
}
</style>
