var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-formsVisualizer",style:(_vm.customStyles)},[(_vm.showHeader)?_c('f7-block-title',{attrs:{"medium":""}},[_vm._v("\n    "+_vm._s(_vm.parsedTemplate.name)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.showHeader)?_c('f7-block-title',[_vm._v("\n    "+_vm._s(_vm.parsedTemplate.description)+"\n  ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.parsedTemplate.detail),function(item,index){return _c('div',{key:index},[(item.type !== 'LIST_TEMPLATE')?_c('hi-forms-container',{attrs:{"layout":_vm.parsedLyoutName(item.type),"view-type":_vm.viewType(item.viewType),"group-title":item.name,"visibility":_vm.visibilitys[item.ref],"close":_vm.groupsClose}},[_c('div',{staticClass:"list",class:{
          horizontal: _vm.isHorizontal(item.type),
          vertical: _vm.isVertical(item.type),
          grid: _vm.isGrid(item.type),
        },attrs:{"slot":_vm.parsedLyoutName(item.type)},slot:_vm.parsedLyoutName(item.type)},_vm._l((item.children),function(children,index2){return _c('ul',{key:index2},[(children)?_c('hi-input-visualizer',{key:_vm.inputsVisualizersKeys[children.ref],ref:children.ref,refInFor:true,attrs:{"row-input":children,"btn-round":_vm.btnRound,"primary":_vm.primary,"secondary":_vm.secondary,"font-color-primary":_vm.fontColorPrimary,"font-color-secondary":_vm.fontColorSecondary,"uploaded-files":_vm.filesToUpload,"edit-value":_vm.finalData[children.ref],"extra-data":_vm.extraData[children.ref],"visibility":_vm.visibilitys[children.ref],"read-only-form":_vm.disabledInputs},on:{"file-event":_vm.fileEvent,"unlink-file-event":_vm.unlinkFileEvent,"gallery-event":_vm.galleryEvent,"unlink-image-event":_vm.unlinkImageEvent,"data-event":_vm.dataBuilder,"reset-manual-edit":_vm.resetManualEdit},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.inputNext(item.children[index2+1].ref)}}}):_vm._e()],1)}),0)]):_vm._e(),_vm._v(" "),(item.type === 'LIST_TEMPLATE')?_c('hi-template-list-visualizer-new',{attrs:{"title":item.name,"reference":item.ref,"primary":_vm.primary,"sub-templates-count":item && _vm.finalData[item.ref] ? _vm.finalData[item.ref].length : null},on:{"new-sublayout-data-group":_vm.newSublayoutDataGroup,"delete-sublayout-data-group":_vm.deleteSublayoutDataGroup,"open-sublayout-data-group":_vm.openItemSubLayout},scopedSlots:_vm._u([{key:"default",fn:function(parentData){return _vm._l((item.children),function(container,index3){return _c('hi-forms-container',{key:index3,attrs:{"layout":_vm.parsedLyoutName(container.type),"view-type":_vm.viewType(container.viewType),"group-title":container.name,"close":_vm.groupsClose}},[_c('div',{class:{
              horizontal: _vm.isHorizontal(container.type),
              vertical: _vm.isVertical(container.type),
              grid: container.viewType === 'grid',
            },attrs:{"slot":_vm.parsedLyoutName(container.type)},slot:_vm.parsedLyoutName(container.type)},_vm._l((container.children),function(children,index2){return _c('div',{key:index2},[_c('hi-input-visualizer',{key:_vm.inputsVisualizersKeys[item.ref][parentData.index][children.ref],ref:`${children.upperRef}.${children.ref}`,refInFor:true,attrs:{"template-index":parentData.index,"row-input":children,"btn-round":_vm.btnRound,"primary":_vm.primary,"secondary":_vm.secondary,"font-color-primary":_vm.fontColorPrimary,"font-color-secondary":_vm.fontColorSecondary,"uploaded-files":_vm.filesToUpload,"read-only-form":_vm.disabledInputs,"edit-value":_vm.finalData[item.ref][parentData.index][children.ref],"extra-data":_vm.extraData[item.ref] && _vm.extraData[item.ref][parentData.index] ? _vm.extraData[item.ref][parentData.index][children.ref]: null,"visibility":_vm.visibilitys[item.ref] && _vm.visibilitys[item.ref][parentData.index]?_vm.visibilitys[item.ref][parentData.index][children.ref]:true},on:{"file-event":_vm.fileEvent,"unlink-file-event":_vm.unlinkFileEvent,"gallery-event":_vm.galleryEvent,"unlink-image-event":_vm.unlinkImageEvent,"data-event":_vm.dataBuilder,"reset-manual-edit":_vm.resetManualEdit}})],1)}),0)])})}}],null,true)}):_vm._e()],1)}),_vm._v(" "),(_vm.showButton)?_c('f7-button',{staticClass:"send-btn margin-top",attrs:{"large":"","fill":"","round":_vm.btnRound}},[_vm._v("\n    "+_vm._s(_vm.btnText)+"\n  ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }