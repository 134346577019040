<template>
  <f7-page
    class="main-newform"
    name="newForm"
    @page:beforein="load"
  >
    <!-- Top Navbar -->
    <navbar
      slot="fixed"
      nav-title="Nuevo formulario"
      back-btn-enable
      :back-callback="() => $f7.views.main.router.navigate('/home/',{reloadAll: true, history: false, ignoreCache: true})"
    />

    <new-form-search-entity-popup-vue
      :opened="searchPopupOpened"
      :entt-code="current_service_code"
      :entt-id="current_service"
      :entt-name="current_service_name"
      @closed="searchPopupOpened = false"
      @item-selected="selectItem"
      @open-qr-reader="openQRReader"
      @open-barcode-reader="openBarcodeReader"
    />

    <new-form-search-entity-filter-popup-vue
      :opened="searchPopupFilterOpened"
      :entt-code="current_service_code"
      :entt-id="manualValue"
      :entt-name="current_service_name"
      @closed="searchPopupFilterOpened = false"
      @item-selected="selectItem"
      @open-qr-reader="openQRReader"
      @open-barcode-reader="openBarcodeReader"
    />

    <f7-popup
      :opened="popupQROpened"
      :tablet-fullscreen="true"
      @popup:closed="popupQROpened = false"
    >
      <f7-page>
        <f7-navbar title="Lectura">
          <f7-nav-right>
            <f7-link popup-close>
              Guardar
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <hi-read-q-r
            :open-camera="isOpenQR"
            @qr-result="emitScannedValue"
          />
        </f7-block>
      </f7-page>
    </f7-popup>

    <f7-popup
      :opened="popupBarcodeOpened"
      :tablet-fullscreen="true"
      @popup:closed="popupBarcodeOpened = false"
    >
      <f7-page>
        <f7-navbar title="Lectura">
          <f7-nav-right>
            <f7-link popup-close>
              Guardar
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <hi-read-barcode
            :open-camera="isOpenBarcode"
            @barcode-result="emitScannedValue"
          />
        </f7-block>
      </f7-page>
    </f7-popup>

    <!-- nuevo busqueda entidad filtrada-->
    <f7-popup
      :opened="popupQROpenedNew"
      :tablet-fullscreen="true"
      @popup:closed="popupQROpenedNew = false"
    >
      <f7-page>
        <f7-navbar title="Lectura QR">
          <f7-nav-right>
            <f7-link popup-close>
              Guardar
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <hi-read-q-r
            :open-camera="isOpenQRNew"
            @qr-result="emitScannedValueNew"
          />
        </f7-block>
      </f7-page>
    </f7-popup>

    <f7-popup
      :opened="popupBarcodeOpenedNew"
      :tablet-fullscreen="true"
      @popup:closed="popupBarcodeOpenedNew = false"
    >
      <f7-page>
        <f7-navbar title="Lectura Código de barras">
          <f7-nav-right>
            <f7-link popup-close>
              Guardar
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <hi-read-barcode
            :open-camera="isOpenBarcodeNew"
            @barcode-result="emitScannedValueNew"
          />
        </f7-block>
      </f7-page>
    </f7-popup>

    <f7-popup
      :opened="popupTextOpened"
      :tablet-fullscreen="true"
      @popup:closed="popupTextOpened = false"
      @popup:close="popupTextOpened = false"
    >
      <f7-page>
        <f7-navbar title="Lectura Manual">
          <f7-nav-right>
            <f7-link @click="setManualValue">
              Buscar
            </f7-link>
            <f7-link
              popup-close
            >
              Cerrar
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <f7-list
            strong-ios
            dividers-ios
            inset-ios
          >
            <f7-list-input
              outline
              label="Valor"
              type="text"
              placeholder=""
              clear-button
              @input="manualValue = $event.target.value"
              @keypress.native.enter="setManualValue"
            />
          </f7-list>
        </f7-block>
      </f7-page>
    </f7-popup>
    <!-- fin nuevo-->

    <f7-block>
      <f7-list v-if="!getCreateNew">
        <select-form
          ref="service"
          :title="title1"
          :options="services"
          print="name"
          @change="selectService"
        />
        <select-form
          ref="template"
          :title="title2"
          :options="templatesTemp"
          :default-option-code="current_template"
          print="name"
          component-id="template"
          @change="selectTemplate"
        />

        <div
          v-if="show_items"
          class="item-label smart-title text-align-left"
        >
          {{ title3 }}
        </div>
        <f7-row v-if="show_items">
          <f7-col width="80">
            <select-form
              ref="resultados"
              :title="''"
              :options="entityFullList"
              print="name"
              :disabled="true"
              :default-option-code="current_item"
            />
          </f7-col>
          <f7-col width="20">
            <f7-button
              fill
              class="padding-horizontal-half"
              @click="searchPopupOpened = true"
            >
              <f7-icon f7="search" />
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-list>

      <f7-list v-else>
        <select-form
          ref="template"
          :title="title2"
          :options="allTemplates"
          :default-option-code="current_template"
          print="name"
          component-id="template"
          @change="selectTemplate2"
        />

        <div
          v-if="show_items"
          class="item-label smart-title text-align-left"
        >
          {{ title3 }}
        </div>
        <f7-row v-if="show_items && !getFilterEntity">
          <f7-col width="80">
            <select-form
              ref="resultados"
              :title="''"
              :options="entityFullList"
              print="name"
              :disabled="true"
              :default-option-code="current_item"
            />
          </f7-col>
          <f7-col width="20">
            <f7-button
              fill
              class="padding-horizontal-half"
              @click="searchPopupOpened = true"
            >
              <f7-icon f7="search" />
            </f7-button>
          </f7-col>
        </f7-row>
        <f7-row v-if="show_items && getFilterEntity">
          <f7-col width="60">
            <select-form
              ref="resultados"
              :title="''"
              :options="entityFullList"
              print="name"
              :disabled="true"
              :default-option-code="current_item"
            />
          </f7-col>
          <f7-col width="40">
            <f7-segmented>
              <f7-button
                fill
                small
                class="no-padding no-margin"
                @click="searchPopupOpened = true"
              >
                <f7-icon f7="search" />
              </f7-button>
              <f7-button
                fill
                small
                class="no-padding no-margin"
                icon-f7="qrcode"
                @click="openQRReaderNew"
              />
              <f7-button
                fill
                small
                class="no-padding no-margin"
                icon-f7="barcode"
                @click="openBarcodeReaderNew"
              />
              <f7-button
                fill
                small
                class="no-padding no-margin"
                icon-f7="pencil"
                @click="openTextReader"
              />
            </f7-segmented>
          </f7-col>
        </f7-row>
      </f7-list>

      <f7-button
        class="margin-top"
        fill
        @click="cumplimentar"
      >
        Crear informe
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import HiReadQR from 'vue-component-library/components/uiBasics/HiReadQR.vue'
import HiReadBarcode from 'vue-component-library/components/uiBasics/HiReadBarcode.vue'

import Navbar from '../components/Navbar.vue';
import SelectForm from '../components/select.vue';
import NewFormSearchEntityPopupVue from './newFormSearchEntityPopup.vue';
import NewFormSearchEntityFilterPopupVue from './newFormSearchEntityFilterPopup.vue';

import EventBus from '../js/EventBus';
import store from '../js/store/index';
import { mapState, mapGetters } from 'vuex';
import ServicesRepository from '../js/repositories/ServicesRepository';
import TemplateRepository from '../js/repositories/TemplateRepository';

export default {
  name: 'NewForm',
  components: {
    HiReadQR,
    HiReadBarcode,
    Navbar,
    SelectForm,
    NewFormSearchEntityPopupVue,
    NewFormSearchEntityFilterPopupVue
  },
  data() {
    return {
      title1: 'Entidad asociada',
      title2: 'Plantilla',
      title3: 'Items',
      exist_service: false,
      services: [],
      templatesTemp: [],
      current_service: '0',
      current_service_code: '',
      current_service_name: '',
      current_template: null,
      current_item: 0,
      show_items: false,
      info_entity: null,
      rol: null,

      searchPopupOpened: false,
      popupQROpened: false,
      popupBarcodeOpened: false,
      popupTextOpened: false,

      popupQROpenedNew: false,
      popupBarcodeOpenedNew: false,
      searchPopupFilterOpened: false,
      manualValue: '',
      originalCurrentDeviceCode: '',

      allTemplates: []
    };
  },
  computed: {
    isOpenQR() {
      return this.popupQROpened;
    },
    isOpenBarcode() {
      return this.popupBarcodeOpened;
    },
    isOpenQRNew() {
      return this.popupQROpenedNew;
    },
    isOpenBarcodeNew() {
      return this.popupBarcodeOpenedNew;
    },
    isOpenText() {
      return this.popupTextOpened;
    },
    ...mapState([
      'confDatatypes',
      'token',
      'latitude',
      'longitude',
    ]),
    ...mapState('EntityCacheSearch', [
      'entityFullList',
    ]),
    ...mapGetters(['getCreateNew', 'getFilterEntity', 'getCurrentTemplate'])
  },
  async mounted() {
    EventBus.$emit('responses.pending.upload');
  },
  methods: {
    async load() {
      if(!this.getCreateNew) {
        await this.loadServices();
        if (!Number(this.current_service)) {
          await this.selectService(null, 'beforin');
        }
      } else {
        await this.loadAllTemplates();
        if(this.getCurrentTemplate && this.getCurrentTemplate !== 0) {
          if(!this.getCreateNew) {
            this.selectTemplate(this.getCurrentTemplate.id)
          } else {
            this.selectTemplate2(this.getCurrentTemplate.id)
          }
        }
      }

    },
    async loadServices() {
      this.services = await ServicesRepository.getAll();
    },
    async loadAllTemplates() {
      this.allTemplates = await TemplateRepository.getAll();
    },
    async selectService(value, stack = null) {
      if (value) {
        this.show_items = true;

        const res = this.services.filter((element) => parseInt(element.id, 10) === parseInt(value, 10));
        if (res) {
          this.title3 = res[0].name;
          this.current_service_code = res[0].code;
          this.originalCurrentDeviceCode = res[0].code;
          this.current_service_name = res[0].name;
        }
        this.current_service = value;
        this.templatesTemp = await TemplateRepository.getByService(value);
        this.resetService();
      } else {
        this.resetServiceAndTemplate(stack);

        //Si no se selecciona valor, se buscan los templates que no tengan servicio asociado
        // this.templatesTemp = await TemplateRepository.getByNotService();
        this.templatesTemp = await TemplateRepository.getByNotServiceRoles(this.token.data.groups.split(','));
      }
    },

    resetService() {
      this.current_template = null;
      this.current_item = 0;
      this.info_entity = null;
      this.rol = null;
      store.commit('setCurrentRol', this.rol);
      this.$refs.template.selected = null;
      // this.$refs.template.updateComponent();
    },

    resetServiceAndTemplate(stack) {
      this.exist_service = false;
      this.current_service = null;
      this.current_service_code = '';
      this.originalCurrentDeviceCode = '';
      this.current_service_name = '';
      if (stack !== 'beforin') {
        this.current_template = null;
        this.current_item = 0;
        this.show_items = false;
        this.info_entity = null;
        this.rol = null;
        store.commit('setCurrentRol', this.rol);
        this.$refs.template.selected = null;
      }
    },

    resetTemplate2() {
      this.exist_service = false;
      this.show_items = false;
      this.title3 = 'Items';
      this.current_service_code = '';
      this.originalCurrentDeviceCode = '';
      this.current_service_name = '';
      this.current_service = '';
      this.current_item = 0;
      this.info_entity = null;
    },
    async selectTemplate2(value) {
      this.current_item = 0;
      this.current_template = value;
      const tempTemplate = await TemplateRepository.getById(this.current_template);
      store.commit('setCurrentTemplate', tempTemplate);
      if (tempTemplate.entity && tempTemplate.entity > 0) {
        this.exist_service = true;
        this.services = await ServicesRepository.getById(tempTemplate.entity);
        if(this.services && this.services.length > 0) {
          const servTemp = this.services[0];
          this.title3 = servTemp.name;
          this.current_service_code = servTemp.code;
          this.originalCurrentDeviceCode = servTemp.code;
          this.current_service_name = servTemp.name;
          this.current_service = servTemp.id.toString();
          this.show_items = true;
        }
      } else {
        this.resetTemplate2();
      }
      this.rol = tempTemplate.rol;
      store.commit('setCurrentRol', this.rol);
    },
    async selectTemplate(value) {
      this.current_template = value;

      // Cuando se selecciona el template, se comprueba si dicho template tiene asociado
      // una entidad de datos (servicio)
      const tempTemplate = await TemplateRepository.getById(this.current_template);
      store.commit('setCurrentTemplate', tempTemplate);
      //const tempTemplate = await this.templatesTemp.filter((element) => element.id = this.current_template);
      if (tempTemplate.entity && tempTemplate.entity > 0) {
        this.exist_service = true;
        this.services = await ServicesRepository.getById(tempTemplate.entity);
      } else {
        this.exist_service = false;
      }
      this.rol = tempTemplate.rol;
      store.commit('setCurrentRol', this.rol);
    },

    async selectItem(value) {
      this.current_item = value;
      this.info_entity = this.entityFullList.filter((element) => parseInt(element.id, 10) === parseInt(value, 10))[0];
    },

    async cumplimentar() {
      if (this.current_template) {
        if (this.current_service && !this.current_item) {
          this.$f7.dialog.alert('Es obligatorio seleccionar un item');
          return;
        }
        const info = {
          idService: this.current_service,
          idTemplate: this.current_template,
          idItem: this.current_item,
        };
        const temp = await TemplateRepository.getById(this.current_template);
        store.commit('setCurrentTemplate', temp);
        store.commit('setInfoCurrentTemplate', info);
        store.commit('setInfoEntity', this.info_entity);
        this.$f7.views.main.router.navigate('/completetemplate/');
      } else {
        this.$f7.dialog.alert('Es obligatorio seleccionar un formulario');
      }
    },

    openQRReader() {
      this.popupQROpened = true;
    },
    openBarcodeReader() {
      this.popupBarcodeOpened = true;
    },
    openQRReaderNew() {
      this.popupQROpenedNew = true;
    },
    openBarcodeReaderNew() {
      this.popupBarcodeOpenedNew = true;
    },
    openTextReader() {
      this.popupTextOpened  = true;
    },
    emitScannedValue({ value }) {
      console.log('emitScannedValue.....', value);
      EventBus.$emit('code-scanned', value);
      this.popupQROpened = false;
      this.popupBarcodeOpened = false;
      this.popupTextOpened = false;
    },

    emitScannedValueNew({ value }) {
      console.log('emitScannedValueNew.....', value);
      EventBus.$emit('code-scanned', value);
      this.manualValue = value;
      this.popupQROpenedNew = false;
      this.popupBarcodeOpenedNew = false;
      this.popupTextOpened = false;
      this.searchPopupFilterOpened = true;
      this.current_service_code = '#' + this.originalCurrentDeviceCode;
    },
    setManualValue() {
      this.popupTextOpened = false;
      if(this.manualValue) {
        this.searchPopupFilterOpened = true;
        this.current_service_code = '#' + this.originalCurrentDeviceCode;
      }

    }
  },
};
</script>

<style lang="scss">
.main-newform {
  .list {
    li {
      list-style: none;
    }
    & > div {
      margin-bottom: 5px;
      .smart-title {
        padding-left: 5px;
      }
    }
  }
  .smart-border {
    .item-content {
      padding-left: 0;
      .item-inner {
        padding-left: 10px;
      }
    }
  }

  .block-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>